export function setItemLocalStorage(key: string, value: string) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value);
  }
}

export function getItemLocalStorage(key: string) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
}

export function removeItemLocalStorage(key: string) {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
}

export function clearLocalStorage() {
  if (typeof window !== 'undefined') {
    localStorage.clear();
  }
}
