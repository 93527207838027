import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import {
  Form,
  Input,
  Button,
  Table,
  Tooltip,
  Row,
  Col,
  Modal,
  Space,
  Spin,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  ReconciliationOutlined,
  InfoCircleOutlined,
  TeamOutlined,
  PropertySafetyFilled,
  PlusOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';
//import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { getCompanyService } from '../../services/companyServices';
import { getItemLocalStorage } from 'utils/localStorege';
import { api } from '../../utils/apiServices';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADMIN } from 'utils/permissions';
import { checkAdminPermission } from './../../utils/permissions';
import { deleteCompanyService } from './../../services/companyServices';
const { Search } = Input;

interface CompaniesTableProps {
  children?: ReactNode;
  currentUser: any;
}

interface pagination {
  current: number;
  pageSize: number;
  position?: string[];
  showSizeChanger?: boolean;
}

const CompaniesTable: React.FC<CompaniesTableProps> = (props) => {
  const { currentUser } = props;
  const history = useHistory();
  const columns: any = [
    {
      title: 'Khách hàng',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Mã số thuế',
      dataIndex: 'taxReferenceNumber',
      key: 'taxReferenceNumber',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'companyAddress',
      key: 'companyAddress',
    },
    {
      title: 'Truy cập',
      key: 'access',
      align: 'center',
      render: (_: string, record: any) => (
        <>
          <Tooltip title="Truy cập">
            <Button
              shape="round"
              icon={<ArrowRightOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/company/detail/${record.companyProfileId}`);
              }}
            ></Button>
          </Tooltip>
        </>
      ),
    },
  ];

  if (checkAdminPermission(props.currentUser?.accountRole)) {
    columns.push({
      title: 'Xóa',
      key: 'remove',
      align: 'center',
      render: (_: string, record: any) => (
        <>
          <Tooltip title="Xóa">
            <Button
              shape="round"
              style={{ marginLeft: 8 }}
              icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalDeleteVisible(true);
                setDeleteCompanyId(record.companyProfileId);
              }}
            ></Button>
          </Tooltip>
        </>
      ),
    });
  }

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [totalCompanies, setTotalCompanies] = useState<number>(0)
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>()
  const [pagingOptions, setPagingOptions] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setLoading(true);
    getCompaniesList({});
  }, []);

  const getCompaniesList = async (params: any, isSearching?: boolean) => {
    const companyList: any = await getCompanyService(params);
    if (companyList?.data?.companyProfiles) {
      setCompanies(companyList.data.companyProfiles);
      setTotalCompanies(companyList.data.total);
      if (isSearching) {
        setPagingOptions({
          current: 1,
          pageSize: 10
        })
      }
    }
    setLoading(false);
  };

  // const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  //   setLoading(true);
  //   let params = {
  //     pageIndex: pagination.current - 1,
  //     pageSize: pagination.pageSize,
  //   };
  //   getCompaniesList(params);
  //   setLoading(false);
  // };

  const handleSearch = (searchText: string) => {
    setSearchValue(searchText);
  };

  useMemo(() => {
    setLoading(true);
    let params = {
      search: searchValue,
    };
    getCompaniesList(params, true);
    setLoading(false);
  }, [searchValue])

  const handleCloseModal = () => {
    setIsModalDeleteVisible(false);
    setDeleteCompanyId(null);
    setConfirmLoading(false);
  };
  const confirmDeleteCompany = () => {
    setConfirmLoading(true);
    deleteCompanyService(deleteCompanyId)
      .then((resp) => {
        setDeleteCompanyId(null);
        setIsModalDeleteVisible(false);
        setLoading(true);
        getCompaniesList({});
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          {' '}
          <div style={{ marginBottom: 16 }}>
            <Search placeholder="Tìm kiếm..." onSearch={handleSearch} />
          </div>
        </Col>
        <Col span={4}>
          {currentUser && currentUser.accountRole.toUpperCase() === ADMIN && (
            <div>
              <Button shape="round" type="primary">
                <Link to="company/create">
                  <PlusOutlined />
                  Thêm{' '}
                </Link>
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Table
        pagination={{
          position: ['bottomCenter'],
          ...pagingOptions,
          total: totalCompanies,
          onChange: (page: number, pageSize: number) => {
            setLoading(true);
            setPagingOptions({ ...pagingOptions, current: page, pageSize })
            let params = {
              pageIndex: page - 1,
              pageSize: pageSize,
              search: searchValue
            };
            getCompaniesList(params);

            setLoading(false);
          }
        }}
        // onChange={handleTableChange}
        loading={loading}
        columns={columns}
        dataSource={companies}
        rowClassName="clickable-row"
        onRow={(record: any) => {
          return {
            onClick: (event) => {
              history.push(`/company/detail/${record.companyProfileId}`);
            }, // click row
          };
        }}
      />
      <Modal
        title={'Thông báo'}
        onCancel={handleCloseModal}
        visible={isModalDeleteVisible}
        onOk={confirmDeleteCompany}
        cancelText={'Thoát'}
        okText={'Tiếp tục XÓA'}
        confirmLoading={confirmLoading}
      >
        {' '}
        'Bạn có chắc chắn muốn xóa Khách hàng này? Lưu ý: thông tin sẽ không thể
        khôi phục sau khi Khách hàng bị xóa khỏi hệ thống.'
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state.accounts;
  return { currentUser };
};

export default connect(mapStateToProps)(CompaniesTable);