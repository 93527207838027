import { AxiosResponse } from 'axios';
import { api } from '../utils/apiServices';

export const getAllAccountsService = (): Promise<AxiosResponse<any>> => {
  return api.get(`account/get-all-account`);
};

export const getCurrentAccountService = (): Promise<AxiosResponse<any>> => {
  return api.get(`account/get-current-account-info`);
};

export const createNewAccountsService = (
  data: any,
): Promise<AxiosResponse<any>> => {
  return api.post('account/create-input-account', data);
};

export const deleteAccountService = (
  accountId: string,
): Promise<AxiosResponse<any>> => {
  return api.delete(`account/delete-input-account?accountId=${accountId}`);
};

export const updateInputAccountService = (
  data,
): Promise<AxiosResponse<any>> => {
  return api.put('account/update-input-account', data);
};

export const updateCurrentAccountService = (
  data,
): Promise<AxiosResponse<any>> => {
  return api.put('account/update-account', data);
};

export const changePasswordService = (data): Promise<AxiosResponse<any>> => {
  return api.put('account/change-password', data);
};
