import React, { ReactNode, useState, useEffect, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers/';
import { LayoutActionTypes } from '../../store/actions/layout/type';
import { Layout, Menu, Drawer } from 'antd';
import {
  UserOutlined,
  RedEnvelopeOutlined,
  FileTextOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { fromPairs } from 'lodash';
import { checkAdminPermission } from 'utils/permissions';
const { SubMenu } = Menu;
const { Sider } = Layout;
interface LeftSideBarProps {
  children?: ReactNode;
  currentUser?: any;
}
const LeftSideBar: React.FC<LeftSideBarProps> = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState('');
  const { sidebar } = useSelector((state: RootState) => state.layout);
  const layoutDispatch = useDispatch<Dispatch<LayoutActionTypes>>();
  useEffect(() => {
    if (props.currentUser) {
      setCurrentUserRole(props.currentUser.accountRole);
    }
    const currentScreenWidth = window.screen.width;
    if (currentScreenWidth < 768) {
      layoutDispatch({ type: 'TOGGLE_SIDEBAR', payload: false });
    }
  }, []);

  const handleToggleSidebar = (value: any) => {
    layoutDispatch({ type: 'TOGGLE_SIDEBAR', payload: value });
  };

  const siderMenu = (
    <Menu
      defaultOpenKeys={['sub1', 'sub2']}
      mode="inline"
      style={{ height: '100%', borderRight: 0, marginTop: '15px' }}
    >
      <Menu.Item key="1" icon={<UserOutlined />}>
        <Link to="/company">Khách hàng</Link>
      </Menu.Item>
      {checkAdminPermission(currentUserRole) && (
        <Menu.Item icon={<RedEnvelopeOutlined />} key="2">
          <Link to="/manage-account">Nhập liệu viên</Link>
        </Menu.Item>
      )}

      <Menu.Item icon={<FileTextOutlined />} key="3">
        <Link to="/templates">Tài nguyên</Link>
      </Menu.Item>

      <Menu.Item icon={<ContactsOutlined />} key="4">
        <Link to="/contact">Liên hệ</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Sider
        breakpoint="lg"
        width={200}
        className="site-layout-background responsive-sider"
        collapsible
        collapsedWidth="0"
        collapsed={!sidebar}
        zeroWidthTriggerStyle={{
          display: 'none',
        }}
      >
        {siderMenu}
      </Sider>
    </>
  );
};

export default LeftSideBar;
