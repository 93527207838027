import { AxiosResponse } from 'axios';
import { api } from '../utils/apiServices';
export const createCompanyService = (
  companyProfile: any,
): Promise<AxiosResponse<any>> => {
  return api.post('company/create-company-profile', companyProfile);
};

export const updateCompanyService = (
  id: string,
  companyProfile: any,
): Promise<AxiosResponse<any>> => {
  return api.put(`company/${id}`, companyProfile);
};

export const getCompanyProfileByIdService = (
  id: string,
): Promise<AxiosResponse<any>> => {
  return api.get(`company/${id}`);
};

export const getCompanyService = (params: any): Promise<AxiosResponse<any>> => {
  return api.get(`company`, { params });
};

export const getCompanyCategoriesService = (
  params: any,
): Promise<AxiosResponse<any>> => {
  return api.get(`/retrieve-company-category-template`, { params });
};

export const checkExamminationResultService = (
  params: any,
): Promise<AxiosResponse<any>> => {
  return api.get(`/check-company-template-input-status`, { params });
};

export const deleteCompanyService = (
  id: string,
): Promise<AxiosResponse<any>> => {
  return api.delete(`company/${id}`);
};
