import React, { ReactNode, useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Table,
  Tooltip,
  Card,
  Modal,
  Descriptions,
} from 'antd';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import {
  InfoCircleOutlined,
  EditOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  ForwardOutlined,
} from '@ant-design/icons';
import { Col, Layout, Row } from 'antd';

import { getItemLocalStorage } from 'utils/localStorege';
import { api } from '../../utils/apiServices';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteEmployeeService,
  getEmployeeListByMedicalExaminationId,
} from '../../services/employee.services';
import EmployeeForm from './EmployeeForm';
import moment from 'moment';
import { checkAdminPermission } from 'utils/permissions';
import { getMedicalExaminationByIdService } from 'services/medicalExaminationServices';
import { getCompanyProfileByIdService } from 'services/companyServices';

const { Search } = Input;

const EmployeeTable: React.FC<any> = (props) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [employees, setEmployees] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(10);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchByAgeValue, setSearchByAgeValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(false);
  const [selectedDeleteEmployee, setSelectedDeleteEmployee] = useState(false);
  const [isModalDeleteVisible, setModalDeleteVisible] = useState(false);
  const dateFormat = 'DD/MM/YYYY';
  const [companyName, setCompanyName] = useState('');
  const [medicalExaminationDetail, setMedicalExaminationDetail] = useState({
    medicalExaminationName: '',
    startDate: '',
    endDate: '',
    currentInputEmployeeQuantity: '',
    employeeQuantity:'',
    companyProfileId:''
  });

  const columns = [
    {
      title: 'Họ và Tên',
      dataIndex: 'employeeName',
      key: 'employeeName',
    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      key: 'gender',
      render: (gender: number, record: any) => (
        <>{gender == 1 ? 'Nữ' : 'Nam'}</>
      ),
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'employeeNo',
      key: 'employeeNo',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'employeePhoneNumber',
      key: 'employeePhoneNumber',
    },

    {
      title: 'Chức năng',
      key: 'action',
      render: (_: string, record: any) => (
        <>
          <Tooltip title="Nhập liệu">
            <Button
              className="primary"
              shape="round"
              icon={<InfoCircleOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                history.push(
                  `/medical-exam-result/${record.employeeProfileId}`,
                );
              }}
            >
              Nhập
            </Button>
          </Tooltip>
          <Tooltip title="Sửa thông tin">
            <Button
              shape="round"
              style={{ marginLeft: 8 }}
              icon={<EditOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                setSelectedEmployee(record);
                setIsModalVisible(true);
              }}
            >
              Sửa
            </Button>
          </Tooltip>

          {checkAdminPermission(props.currentUser.accountRole) && (
            <Tooltip title="Xóa nhân viên">
              <Button
                shape="circle"
                style={{ marginLeft: 8 }}
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                onClick={() => {
                  setSelectedDeleteEmployee(record);
                  showDeleteConfirmationModal();
                }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    searchEmployee();
  }, [searchValue]);

  useEffect(() => {
    getMedicalExaminationList();
  }, []);

  const getEmployeeList = async (params: any) => {
    try {
      setLoading(true);
      let employeeList: any = await getEmployeeListByMedicalExaminationId(
        params,
      );

      if (employeeList.data) {
        setTotalEmployees(employeeList.data.totalEmployee);
        setEmployees(employeeList.data.employeeProfile);
      }
    } finally {
      setLoading(false);
    }
  };

  const getMedicalExaminationList = async () => {
    const medicalExaminationDetail: any = await getMedicalExaminationByIdService(
      id, //Medical examination ID
    );
    if (medicalExaminationDetail?.data) {
      setMedicalExaminationDetail(medicalExaminationDetail.data);
      let companyProfileId = medicalExaminationDetail.data.companyProfileId;

      let companyInfo = companyProfileId
        ? await getCompanyProfileByIdService(companyProfileId)
        : null;
      if (companyInfo?.data) {
        setCompanyName(companyInfo.data.companyName);
      }
    }
  };

  const deleteEmployee = async (employee: any) => {
    try {
      setLoading(true);
      if (!employee) return;

      let deleteEmployeeServiceResponse: any = await deleteEmployeeService(
        employee.employeeProfileId,
      );

      setModalDeleteVisible(false);
      //Refresh list employee
      getEmployeeList({ companyMedicalExaminationID: id });
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteEmployee = () => {
    if (!selectedDeleteEmployee) return;

    deleteEmployee(selectedDeleteEmployee);
  };

  const handleUpdateEmployee = () => {
    let params = {
      companyMedicalExaminationID: id,
      searchValue: searchValue,
      age: searchByAgeValue,
    };
    getEmployeeList(params);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let params = {
      companyMedicalExaminationID: id,
      searchValue: searchValue,
      pageIndex: pagination.current - 1,
      pageSize: pagination.pageSize,
    };
    getEmployeeList(params);
  };

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const searchEmployee = async () => {
    let params = {
      companyMedicalExaminationID: id,

      searchValue: searchValue,
    };

    getEmployeeList(params);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedEmployee(false);
    setModalDeleteVisible(false);
    setSelectedDeleteEmployee(false);
  };

  const onFinishFailed = () => {
    setSubmitLoading(false);
  };

  const showDeleteConfirmationModal = () => {
    setModalDeleteVisible(true);
  };

  const redirectToCompanyProfilePage = () => {
    let companyProfileUrl =
      '/company/detail/' + medicalExaminationDetail.companyProfileId;

    window.open(companyProfileUrl, '_blank');
  };

  return (
    <>
      <Row>
        <Descriptions
          className="employee-info-container "
          title={companyName} //"Tên khách hàng"
          extra={
            <Button
              onClick={redirectToCompanyProfilePage}
              shape="round"
              style={{ marginLeft: 20 }}
              type="primary"
              icon={<ForwardOutlined />}
            >
              Tới trang thông tin
            </Button>
          }
        >
          <Descriptions.Item label="Kỳ khám">
            <b>{medicalExaminationDetail.medicalExaminationName}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Ngày bắt đầu">
            <b>
              {medicalExaminationDetail.startDate
                ? moment(medicalExaminationDetail.startDate).format(
                    'DD/MM/yyyy',
                  )
                : ''}
            </b>
          </Descriptions.Item>
          <Descriptions.Item label="Ngày kết thúc">
            <b>
              {medicalExaminationDetail.endDate
                ? moment(medicalExaminationDetail.endDate).format('DD/MM/yyyy')
                : ''}
            </b>
          </Descriptions.Item>
          <Descriptions.Item className="current-input-quantity" label="Số hiện tại">
            <p className='current-employee-input-quantity'>{medicalExaminationDetail.currentInputEmployeeQuantity}</p>
            <p className='current-employee-quantity'>
            &nbsp;/&nbsp; {medicalExaminationDetail.employeeQuantity}
            </p>
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row>
        <Col span={6}>
          {' '}
          <div style={{ marginBottom: 16 }}>
            <Input.Search
              placeholder="Tìm theo tên, mã nhân viên hoặc số điện thoại"
              onSearch={handleSearch}
              style={{ maxWidth: 450 }}
            />
          </div>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            style={{ marginLeft: 50 }}
            onClick={() => {
              setIsModalVisible(true);
            }}
            shape="round"
            icon={<PlusOutlined />}
          >
            Thêm
          </Button>
        </Col>
      </Row>

      <Table
        loading={loading}
        columns={columns}
        dataSource={employees}
        onChange={handleTableChange}
        scroll={{ x: true }}
        pagination={{
          position: ['bottomCenter'],
          total: totalEmployees,
        }}
      />

      <Modal
        title={selectedEmployee ? 'Thông tin cá nhân' : 'Thêm người mới'}
        closable
        onCancel={handleCloseModal}
        visible={isModalVisible}
        footer={null}
      >
        <EmployeeForm
          closeModel={handleCloseModal}
          employeeInfo={selectedEmployee}
          finishedUpdateEmployee={handleUpdateEmployee}
          medicalExaminationId={id}
        />
      </Modal>

      <Modal
        title={'Thông báo'}
        onCancel={handleCloseModal}
        visible={isModalDeleteVisible}
        onOk={confirmDeleteEmployee}
        cancelText={'Thoát'}
        okText={'Tiếp tục XÓA'}
      >
        {' '}
        'Bạn có chắc chắn muốn xóa người này? Lưu ý: Thông tin của người này sẽ
        không thể phục hồi sau khi bị xóa khỏi hệ thống.'
      </Modal>
    </>
  );
};

export default EmployeeTable;
