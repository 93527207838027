import { combineReducers } from 'redux';
import { app } from './app';
import { layoutReducers } from './layoutReducers';
import { companyReducers } from './companyReducers';
import { accountReducers } from './accountReducer';

export const rootReducer = combineReducers({
  app,
  layout: layoutReducers,
  company: companyReducers,
  accounts: accountReducers,
});

export type RootState = ReturnType<typeof rootReducer>;
