import React, { ReactNode, useState } from 'react';
import { Form, Input, Button, Upload, Card, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  InboxOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
//import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import {
  createCompanyService,
  updateCompanyService,
} from '../../services/companyServices';
import { getMedicalExaminationByIdService } from '../../services/medicalExaminationServices';
import {
  createEmployeeService,
  updateEmployeeService,
} from '../../services/employee.services';
import { getItemLocalStorage } from 'utils/localStorege';
import { api } from '../../utils/apiServices';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
  formLayout: 'horizontal',
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

interface EmployeeProfileProps {
  children?: ReactNode;
  employeeInfo?: any;
  closeModel?: any;
  medicalExaminationId: string;
  finishedUpdateEmployee?: any;
}

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}
const EmployeeProfile: React.FC<EmployeeProfileProps> = (props) => {
  const [employeeForm] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  if (props.employeeInfo) {
    employeeForm.setFieldsValue({
      employeeName: props.employeeInfo.employeeName || '',
      dateOfBirth: props.employeeInfo.dateOfBirth || '',
      employeeNo: props.employeeInfo.employeeNo || '',
      employeeEmail: props.employeeInfo.employeeEmail || '',
      employeePhoneNumber: props.employeeInfo.employeePhoneNumber || '',
      employeePosition: props.employeeInfo.employeePosition || '',
      employeeBusinessUnit: props.employeeInfo.employeeBusinessUnit || '',
      employeeUnit: props.employeeInfo.employeeUnit || '',
      medicalExaminationNo: props.employeeInfo.medicalExaminationNo || '',
      gender: props.employeeInfo.gender || 0,
      assign: props.employeeInfo.assign || '',
      note: props.employeeInfo.note || '',
    });
  } else {
    employeeForm.setFieldsValue({
      employeeName: '',
      dateOfBirth: '',
      employeeNo: '',
      employeeEmail: '',
      employeePhoneNumber: '',
      employeePosition: '',
      medicalExaminationNo: '',
      gender: 0,
      assign: '',
      note: '',
    });
  }

  const history = useHistory();
  const onFinishFailed = () => {
    setSubmitLoading(false);
  };
  const onFinish = async (e: any) => {
    setSubmitLoading(true);
    const companyMedicalExaminationData = await getMedicalExaminationByIdService(
      props.medicalExaminationId,
    );
    let companyProfileId = null;
    if (companyMedicalExaminationData?.data) {
      companyProfileId = companyMedicalExaminationData.data.companyProfileId;
    }
    if (!!props.employeeInfo) {
      let updateData = {
        ...e,
        companyProfileId: companyProfileId,
        companyMedicalExaminationId: props.medicalExaminationId,
        employeeProfileId: props.employeeInfo.employeeProfileId,
      };
      updateEmployeeService(props.employeeInfo.employeeProfileId, updateData)
        .then(() => {
          props.finishedUpdateEmployee();
        })
        .catch((e) => {})
        .finally(() => {
          setSubmitLoading(false);
          window.location.reload();
        });
    } else {
      let createData = {
        ...e,
        companyProfileId: companyProfileId,
        companyMedicalExaminationId: props.medicalExaminationId,
        createdDate: props.employeeInfo.createDate,
        createdBy: props.employeeInfo.createdBy,
        employeeProfileVersion: props.employeeInfo.employeeProfileVersion,
        employeeHealthClass: props.employeeInfo.employeeHealthClass,
      };
      createEmployeeService(createData)
        .then(() => {
          props.finishedUpdateEmployee();
        })
        .catch((e) => {})
        .finally(() => {
          setSubmitLoading(false);
          window.location.reload();
        });
    }
    if (!!props.closeModel) {
      props.closeModel();
    }
  };

  return (
    <Form
      {...layout}
      name="employeeForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={employeeForm}
    >
      <Form.Item
        label="Họ và Tên"
        name="employeeName"
        rules={[{ required: true, message: 'Vui lòng nhập tên nhân viên' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="gender"
        label="Giới tính"
        rules={[{ required: true, message: 'Vui chọn giới tính' }]}
      >
        <Select
          defaultValue={0}
          placeholder="Giới tính nhân viên"
          onChange={(value) => {
            employeeForm.setFieldsValue({ gender: value });
          }}
        >
          <Option value={0}>Nam</Option>
          <Option value={1}>Nữ</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Năm sinh"
        name="dateOfBirth"
        rules={[{ required: true, message: 'Vui lòng nhập ngày sinh' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Bộ phận làm việc" name="employeePosition">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Cơ quan" name="employeeUnit">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Nhóm" name="employeeBusinessUnit">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Mã nhân viên" name="employeeNo">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Điện thoại" name="employeePhoneNumber">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Email" name="employeeEmail">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Chỉ định" name="assign">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Ghi chú" name="note">
        <Input style={{ width: '100%' }} />
      </Form.Item>

      {/* </Card> */}

      <Form.Item
        className="company-create-form-button"
        style={{ marginBottom: 0 }}
      >
        <Text type="danger" className="center"></Text>
      </Form.Item>

      <Form.Item className="company-create-form-button">
        <Button type="primary" htmlType="submit" loading={submitLoading}>
          {!!props.employeeInfo ? 'Cập nhật' : 'Thêm mới'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmployeeProfile;
