import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Upload, Progress } from 'antd';
import Text from 'antd/lib/typography/Text';
import { api } from '../../utils/apiServices';
import { getHealthCareCenterInfoByAccount } from 'services/healthCareCenter.services';
import { checkAdminPermission } from 'utils/permissions';
import moment from 'moment';
const BASE_URL = process.env.REACT_APP_API_URL;

export const EditAccountForm = ({
  handleEditAccountSubmit = (e: any) => {},
  getEditAccountInitialValues = () => {
    return {};
  },
  error,
  loadingModal,
  profile,
}) => {
  const [healthCareCenter, setHealthCareCenter] = useState<any>(null);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [countDown, setCountdown] = useState(0);
  const [countDownDays, setCountdownDays] = useState(0);

  useEffect(() => {
    gethealthCareCenter();
    calculateCountDown();
  }, []);

  const gethealthCareCenter = async () => {
    const healthCareCenterInfo = await getHealthCareCenterInfoByAccount();
    console.log(healthCareCenterInfo);
    setHealthCareCenter(healthCareCenterInfo?.data);
  };

  const calculateCountDown = () => {
    var startTime = moment();
    var endTime = moment(profile.createdDate).add(1, 'y');

    var countDown = moment
      .duration(
        moment(endTime, 'DD/MM/yyyy').diff(moment(startTime, 'DD/MM/yyyy')),
      )
      .asDays();

    var countDownPercentage = Math.floor((countDown / 365) * 100);
    setCountdownDays(Math.floor(countDown));
    setCountdown(countDownPercentage);
  };

  const uploadProps = {
    name: 'LogoFile',
    accept: '.jpg,.jpeg,.png',
    showUploadList: false,
    customRequest(data: any) {
      uploadLogoFile(data);
    },
  };
  const uploadLogoFile = async (e: any) => {
    setIsLogoUploading(true);
    let formData = new FormData();
    formData.append('FormFile', e.file);
    let response = await api.postForm(
      `${process.env.REACT_APP_API_URL}HealthCareCenters/upload-health-care-center-logo`,
      formData,
    );
    setIsLogoUploading(false);
    window.location.reload();
  };
  return (
    <>
      <Form
        name="edit_account_form"
        onFinish={handleEditAccountSubmit}
        initialValues={getEditAccountInitialValues()}
      >
        <Form.Item
          label="Địa chỉ email"
          required={false}
          key="email"
          name="email"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Input
            placeholder="Địa chỉ email"
            style={{ width: '80%' }}
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          label="Họ và Tên"
          required={false}
          key="fullName"
          name="fullName"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Input placeholder="Họ và Tên" style={{ width: '80%' }} />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          required={false}
          key="phoneNumber"
          name="phoneNumber"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Input placeholder="Số điện thoại" style={{ width: '80%' }} />
        </Form.Item>

        {error && (
          <Form.Item style={{ marginBottom: 0 }}>
            <Text type="danger" className="center">
              {error}
            </Text>
          </Form.Item>
        )}
        {checkAdminPermission(profile.accountRole) && (
          <Form.Item
            label="Logo/Hình ảnh"
            required={false}
            key="Logo"
            name="logo"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <div>
              <div style={{ maxWidth: 400, marginBottom: 5 }}>
                {!!healthCareCenter && (
                  <img
                    style={{ maxHeight: 100 }}
                    src={`${BASE_URL}healthCareCenters/get-logo-file?path=${healthCareCenter.logoUrl}`}
                  />
                )}
              </div>
              <Upload {...uploadProps}>
                <Button loading={isLogoUploading} shape="round">
                  Tải ảnh lên
                </Button>
              </Upload>
            </div>
          </Form.Item>
        )}

        <Form.Item
          label="Ngày khởi tạo"
          required={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ display:'none' }}
        >
          <Input
            value={moment(profile.createdDate).format('DD/MM/yyyy')}
            disabled
            style={{ width: '80%',display:'none' }}
          />
        </Form.Item>

        <Form.Item
          label="Ngày hết hạn"
          required={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Input
            value={moment(profile.createdDate).add(1, 'y').format('DD/MM/yyyy')}
            disabled
            style={{ width: '80%' }}
          />
        </Form.Item>

        <Form.Item
          label="Còn lại"
          required={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          {countDown < 20 && (
            <Progress
              type="circle"
              percent={countDown}
              status="exception"
              format={() => `${countDownDays} ngày`}
            />
          )}

          {countDown < 70 && countDown >= 20 && (
            <Progress
              type="circle"
              percent={countDown}
              status="normal"
              format={() => `${countDownDays} ngày`}
            />
          )}

          {countDown >= 70 && (
            <Progress
              type="circle"
              percent={countDown}
              status="success"
              format={() => `${countDownDays} ngày`}
            />
          )}
        </Form.Item>

        <Form.Item
          label="Loại tài khoản"
          required={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Input
            value={profile.accountType}
            disabled
            style={{ width: '80%' }}
          />
        </Form.Item>

        <Col span={4} offset={12}>
          <Form.Item>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              loading={loadingModal}
            >
              Cập nhật
            </Button>
          </Form.Item>
        </Col>
      </Form>
      <Row gutter={16}>
        <Col span={2} offset={22}>
          <p>Version: {profile?.accountVersion}</p>
        </Col>
      </Row>
    </>
  );
};
