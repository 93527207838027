import { AppActionTypes } from '../actions/app/type';
import { APP_CONSTANTS } from '../constants';
import _ from 'lodash';

interface LayoutInterface {
  sidebar: boolean;
}

const initialState: LayoutInterface = {
  sidebar: true,
};

export const layoutReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case APP_CONSTANTS.TOGGLE_SIDEBAR:
      return { ...state, ...{ sidebar:action.payload } };
    default:
      return state;
  }
};
