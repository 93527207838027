import { SET_MENU, SET_PAGE_TITLE } from '../../models/app.modal';
import { APP_CONSTANTS } from 'store/constants';
import { AppActionTypes } from './type';

export function setPageTitle(payload: SET_PAGE_TITLE): AppActionTypes {
  return {
    type: APP_CONSTANTS.SET_PAGE_TITLE,
    payload,
  };
}

export function setMenuActive(payload: SET_MENU): AppActionTypes {
  return {
    type: APP_CONSTANTS.SET_MENU,
    payload,
  };
}

export function getCategoriesAction(): any {
  return {
    type: APP_CONSTANTS.GET_CATEGORIES,
  };
}

export function getCategoriesSuccessAction(payload: any): any {
  return {
    type: APP_CONSTANTS.GET_CATEGORIES_SUCCESS,
    payload,
  };
}

export function addNewCategoryDetailAction(payload: any): any {
  return {
    type: APP_CONSTANTS.ADD_NEW_CATEGORY_DETAIL,
    payload,
  };
}

export function updateNewCategoryDetailDescriptionAction(payload: any): any {
  return {
    type: APP_CONSTANTS.UPDATE_NEW_CATEGORY_DETAIL_DESCRIPTION,
    payload,
  };
}
