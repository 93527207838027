export const APP_CONSTANTS = {
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
  SET_MENU: 'SET_MENU',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',

  //Company categories
  GET_COMPANY_CATEGORIES: 'GET_COMPANY_CATEGORIES',
  GET_COMPANY_CATEGORIES_SUCCESS: 'GET_COMPANY_CATEGORIES_SUCCESS',
  ADD_NEW_CATEGORY_DETAIL: 'ADD_NEW_CATEGORY_DETAIL',
  UPDATE_NEW_CATEGORY_DETAIL_DESCRIPTION:
    'UPDATE_NEW_CATEGORY_DETAIL_DESCRIPTION',
};
