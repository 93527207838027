export const BMI_CONSTANTS = {
  VERY_SEVERELY_UNDERWEIGHT: 'Gầy độ 3',
  SEVERELY_UNDERWEIGHT: 'Gầy độ 2',
  UNDERWEIGHT: 'Gầy độ 1',
  NORMAL: 'Bình thường',
  OVERWEIGHT: 'Thừa cân',
  MODERATELY_OBESE: 'Béo phì độ 1',
  SEVERELY_OBESE: 'Béo phì độ 2',
  VERY_SEVERELY_OBESE: 'Béo phì độ 3',
};
