import { AxiosResponse } from 'axios';
import { api } from '../utils/apiServices';

export const getCategoriesServices = (): Promise<AxiosResponse<any>> => {
  return api.get('category');
};

export const checkExamminationResultSaga = (): Promise<AxiosResponse<any>> => {
  return api.get('api/check-company-template-input-status');
};
