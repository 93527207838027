import React, { ReactNode, useState, useEffect, useRef } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import {
  Form,
  Input,
  Button,
  Table,
  Tooltip,
  Modal,
  DatePicker,
  Upload,
  Row,
  Col,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  InfoCircleOutlined,
  EditOutlined,
  InboxOutlined,
  FileDoneOutlined,
  TeamOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
//import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import {
  createMedicalExaminationService,
  deleteMedicalExaminationService,
  getMedicalExaminationService,
  updateMedicalExaminationService,
} from '../../services/medicalExaminationServices';
import { getItemLocalStorage } from 'utils/localStorege';
import { api } from '../../utils/apiServices';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import moment from 'moment';
import { checkAdminPermission } from 'utils/permissions';
import { select } from '@redux-saga/core/effects';
//import { OrganizeImportsScope } from 'typescript';
const { Search } = Input;
const BASE_URL = process.env.REACT_APP_API_URL;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
  formLayout: 'horizontal',
};

interface MedicalExaminationTableProps {
  children?: ReactNode;
  companyId?: string;
  companyInfo?: any;
  currentUser?: any;
}

// const data = [
//   {
//     key: '1',
//     startDate: '2021-04-02T00:00:00',
//     medicalExaminationName: "Khám FPT đợt 1"
//   },
//   {
//     key: '2',
//     startDate: '2021-05-02T00:00:00',
//     medicalExaminationName: "Khám FPT đợt 2"
//   },
// ];

const MedicalExaminationTable: React.FC<MedicalExaminationTableProps> = (
  props,
) => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [medicalExaminations, setMedicalExaminations] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResultModalVisible, setIsResultModalVisible] = useState(false);
  const [contactUrl, setContactUrl] = useState('');
  const [medicalExaminationId, setMedicalExaminationId] = useState('');
  const [fields, setFields] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedMedicalExamination, setSelectedMedicalExamination] = useState(
    '',
  );
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const history = useHistory();
  const formRef = React.createRef<FormInstance>();
  useEffect(() => {
    formRef.current?.validateFields();
  }, [contactUrl]);

  const uploadProps = {
    name: 'employeeFile',
    accept: '.xlsx,.csv',
    showUploadList: false,
    customRequest(data: any) {
      uploadMedicalResultFile(data);
    },
  };
  const columns = [
    {
      title: 'Đợt khám',
      dataIndex: 'medicalExaminationName',
      key: 'medicalExaminationName',
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate: string, record: any) => (
        <>
          <span>{startDate ? moment(startDate).format('DD/MM/YYYY') : ''}</span>
        </>
      ),
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate: string, record: any) => (
        <>
          <span>{endDate ? moment(endDate).format('DD/MM/YYYY') : ''}</span>
        </>
      ),
    },
    {
      title: 'Nhập liệu',
      key: 'action',
      render: (_: string, record: any) => (
        <>
          {checkAdminPermission(props.currentUser.accountRole) && (
            <Tooltip title="Sửa">
              <Button
                shape="circle"
                style={{ marginRight: 8 }}
                icon={<EditOutlined style={{ fontSize: 18 }} />}
                onClick={() => {
                  //Bind data cho pop up medical examination detail khi nhấn nút edit
                  let fieldsData = [
                    {
                      name: ['medicalExaminationName'],
                      value: record.medicalExaminationName,
                    },
                    {
                      name: ['medicalExaminationLeader'],
                      value: record.medicalExaminationLeader,
                    },
                    {
                      name: ['startDate'],
                      value: record.startDate ? moment(record.startDate) : '',
                    },
                    {
                      name: ['endDate'],
                      value: record.endDate ? moment(record.endDate) : '',
                      errors: [],
                    },
                    {
                      name: ['contractNumber'],
                      value: record.contractNumber,
                      errors: [],
                    },
                  ];
                  setMedicalExaminationId(record.companyMedicalExaminationId);
                  setContactUrl(record.contactUrl);
                  setFields(fieldsData);
                  setIsModalVisible(true);
                }}
              />
            </Tooltip>
          )}

          {checkAdminPermission(props.currentUser.accountRole) && (
            <Tooltip title="Tạo form nhập liệu">
              <Button
                shape="circle"
                icon={
                  <InfoCircleOutlined
                    style={{ fontSize: 18 }}
                    onClick={() => {
                      history.push(
                        `/create-template/${record.companyMedicalExaminationId}`,
                      );
                    }}
                  />
                }
              />
            </Tooltip>
          )}

          <Tooltip title="Nhập liệu">
            <Button
              shape="circle"
              style={{ marginLeft: 8 }}
              icon={
                <FileDoneOutlined
                  style={{ fontSize: 18 }}
                  onClick={() => {
                    history.push(
                      `/medical-examination-employee/${record.companyMedicalExaminationId}`,
                    );
                  }}
                />
              }
            />
          </Tooltip>
          {/* {checkAdminPermission(props.currentUser?.accountRole) && (
            <Tooltip title="Xóa kỳ khám">
              <Button
                shape="circle"
                style={{ marginLeft: 8 }}
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                onClick={(e) => {
                  setIsModalDeleteVisible(true);
                  setSelectedMedicalExamination(
                    record.companyMedicalExaminationId,
                  );
                }}
              />
            </Tooltip>
          )} */}
        </>
      ),
    },
  ];
  if (checkAdminPermission(props.currentUser.accountRole)) {
    columns.push({
      title: 'Kết quả',
      key: 'result',
      render: (_: string, record: any) => (
        <>
          {checkAdminPermission(props.currentUser.accountRole) && (
            <Button
              shape="round"
              onClick={() => {
                setMedicalExaminationId(record.companyMedicalExaminationId);
                setIsResultModalVisible(true);
              }}
            >
              Kết quả
            </Button>
          )}
        </>
      ),
    });
  }

  useEffect(() => {
    // setLoading(true)
    getMedicalExaminationList({ companyProfileId: props.companyId });
  }, [props.companyId]);

  const uploadMedicalResultFile = async (e: any) => {
    let formData = new FormData();
    formData.append('FormFile', e.file);
    formData.append('CompanyMedicalExaminationID', selectedMedicalExamination);
    formData.append('Email', props.currentUser.email);
    setSelectedMedicalExamination('');
    let response = await api.postForm(
      `${process.env.REACT_APP_API_URL}MedicalExamination/upload-medical-examination-result`,
      formData,
    );
  };

  const getMedicalExaminationList = async (params: any) => {
    const medicalExaminationList: any = await getMedicalExaminationService(
      params,
    );
    if (medicalExaminationList?.data) {
      setMedicalExaminations(medicalExaminationList.data);
    }
    // setMedicalExaminations(medicalExaminationList)
    setLoading(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setLoading(true);
    let params = {
      pageIndex: pagination.current - 1,
      pageSize: pagination.pageSize,
      companyProfileId: props.companyId,
    };
    getMedicalExaminationList(params);
    setLoading(false);
  };

  const handleSearch = (searchValue: string) => {
    setLoading(true);
    let params = {
      search: searchValue,
      companyProfileId: props.companyId,
    };
    getMedicalExaminationList(params);
    setLoading(false);
  };

  const handleCreateMedicalExamination = async (e: any) => {
    setSubmitLoading(true);
    e.companyProfileId = props.companyId;
    e.startDate = e.startDate ? e.startDate?.utc().format() : '';
    e.endDate = e.endDate ? e.endDate?.utc().format() : '';
    e.contactURL = contactUrl;

    if (
      // e.startDate &&
      // e.endDate &&
      e.companyProfileId &&
      e.medicalExaminationName
      //e.medicalExaminationLeader
    ) {
      let response;
      if (medicalExaminationId) {
        response = await updateMedicalExaminationService(
          medicalExaminationId,
          e,
        );
      } else {
        response = await createMedicalExaminationService(e);
      }

      if (response.data) {
        const { data, error } = response.data;
        if (error) {
          setSubmitLoading(false);
        }
        // if (medicalExaminationId) {
        //   history.push(`/create-template/${medicalExaminationId}`);
        // }
        getMedicalExaminationList({ companyProfileId: props.companyId });
      }
    }
    setSubmitLoading(false);
    setIsModalVisible(false);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setContactUrl('');
    setMedicalExaminationId('');
    setFields([
      { name: ['medicalExaminationName'], value: '' },
      { name: ['medicalExaminationLeader'], value: '' },
      { name: ['startDate'], value: null },
      {
        name: ['endDate'],
        value: null,
      },
    ]);
    setFileList([]);
  };

  const handleCloseResultModal = () => {
    setIsResultModalVisible(false);
  };

  const onFinishFailed = () => {
    setSubmitLoading(false);
  };

  const uploadContactFile = (e: any) => {
    let formData = new FormData();
    formData.append('FormFile', e.file);
    formData.append('TaxReferenceNumber', props.companyInfo.taxReferenceNumber);
    formData.append('filename', e.file.name);

    let response = api.postForm(
      `${process.env.REACT_APP_API_URL}company/validate-file`,
      formData,
    );
    response
      .then((res) => {
        setContactUrl(res.data.url);
        setFileList([e.file]);
        e.onSuccess(e.file);
      })
      .catch((err: any) => {
        e.onError({ event: err });
      });
  };

  const handleCloseModalDelete = () => {
    setIsModalDeleteVisible(false);
    // setSelectedMedicalExamination(null);
    setConfirmLoading(false);
  };
  const handleOkDelete = () => {
    setConfirmLoading(true);
    deleteMedicalExaminationService(medicalExaminationId)
      .then((resp) => {
        getMedicalExaminationList({ companyProfileId: props.companyId });
        setIsModalDeleteVisible(false);
        setIsModalVisible(false);
        // setSelectedMedicalExamination(null);
        setMedicalExaminationId(null);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          {' '}
          <div style={{ marginBottom: 16 }}>
            <Search
              placeholder="Tìm kiếm..."
              onSearch={handleSearch}
              style={{ maxWidth: 300 }}
            />
          </div>
        </Col>
        <Col span={4}>
          {checkAdminPermission(props.currentUser.accountRole) && (
            <div>
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  setIsModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                Thêm
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={medicalExaminations}
        loading={loading}
        pagination={{
          position: ['bottomCenter'],
        }}
      />

      <Form
        {...formLayout}
        id="medicalExaminationForm"
        name="create-medical-examination"
        ref={formRef}
        fields={fields}
        onFinish={handleCreateMedicalExamination}
        onFinishFailed={onFinishFailed}
      >
        <Modal
          title="Lần khám sức khỏe"
          closable
          cancelText="Hủy"
          okText="Tạo đợt khám"
          // onOk={handleCreateMedicalExamination}
          onCancel={handleCloseModal}
          visible={isModalVisible}
          footer={[
            <>
              {!!medicalExaminationId && (
                <Button
                  key="back"
                  onClick={() => {
                    setIsModalDeleteVisible(true);
                  }}
                  loading={submitLoading}
                  style={{ float: 'left' }}
                >
                  Xóa
                </Button>
              )}
            </>,
            <Button
              form="medicalExaminationForm"
              key="submit"
              type="primary"
              htmlType="submit"
              loading={submitLoading}
            >
              Cập nhật
            </Button>,
          ]}
        >
          <Form.Item
            label={`Lần khám`}
            name={'medicalExaminationName'}
            rules={[{ required: true, message: 'Vui lòng nhập tên đợt khám' }]}
          >
            <Input placeholder="Tên/Mã lần khám mới" />
          </Form.Item>

          <Form.Item
            name="contactURL"
            label="Hợp đồng"
            // rules={[
            //   {
            //     validator: () => {
            //       return contactUrl
            //         ? Promise.resolve()
            //         : Promise.reject(new Error('Chưa tải lên hợp đồng'));
            //     },
            //   },
            // ]}
          >
            <Form.Item name={'contractNumber'}>
              <Input placeholder="Mã / Số hợp đồng" />
            </Form.Item>
            <Upload.Dragger
              name="contactFile"
              style={{ width: '100%' }}
              multiple={false}
              fileList={fileList}
              onRemove={() => {
                setFileList([]);
                setContactUrl('');
              }}
              customRequest={uploadContactFile}
            >
              <p className="ant-upload-drag-icon">
                {contactUrl ? <FileDoneOutlined /> : <InboxOutlined />}
              </p>
              <p className="ant-upload-text">
                {contactUrl
                  ? contactUrl.split('\\').slice(-1)[0] || ''
                  : 'Upload file Hợp đồng'}
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
            {!!contactUrl && (
              <div className="download-contact-button">
                <Button>
                  <a
                    href={`${BASE_URL}MedicalExamination/get-contact-file?path=${contactUrl}`}
                    target="_blank"
                  >
                    Download
                  </a>
                </Button>
              </div>
            )}
          </Form.Item>

          <Form.Item
            label={`Thời gian bắt đầu`}
            name={'startDate'}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Vui lòng nhập ngày bắt đầu đợt khám',
            //   },
            // ]}
          >
            <DatePicker
              placeholder="ngày bắt đầu"
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label={`Thời gian kết thúc`}
            name={'endDate'}
            dependencies={['startDate']}
            rules={[
              // {
              //   required: true,
              //   message: 'Vui lòng nhập ngày kết thúc đợt khám',
              // },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  let currentStartDate = getFieldValue('startDate');
                  if (currentStartDate && value) {
                    if (!moment(value)) {
                      return Promise.reject(
                        new Error('Ngày kết thúc không hợp lệ'),
                      );
                    }
                    if (moment(currentStartDate).isAfter(value)) {
                      return Promise.reject(
                        new Error('Ngày kết thúc phải phải sau ngày bắt đầu'),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              placeholder="ngày kết thúc"
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label={`Trưởng đoàn`}
            name={'medicalExaminationLeader'}
            // rules={[{ required: true, message: 'Vui lòng nhập tên trưởng đoàn khám' }]}
          >
            <Input placeholder="Họ và Tên" />
          </Form.Item>
        </Modal>
      </Form>

      <Modal
        title={'Thông báo'}
        onCancel={handleCloseModalDelete}
        visible={isModalDeleteVisible}
        onOk={handleOkDelete}
        cancelText={'Thoát'}
        okText={'Xóa lượt khám'}
        confirmLoading={confirmLoading}
      >
        {' '}
        'Bạn có chắc chắn muốn xóa lượt khám này? Lưu ý: thông tin sẽ không thể
        khôi phục sau khi lượt khám khám bị xóa khỏi hệ thống.'
      </Modal>
      <Modal
        title="Tải về kết quả khám sức khỏe"
        closable
        cancelText="Hủy"
        // onOk={handleCreateMedicalExamination}
        onCancel={handleCloseResultModal}
        visible={isResultModalVisible}
        footer={[
          <Button key="back" onClick={handleCloseResultModal}>
            Thoát
          </Button>,
        ]}
      >
        <div className="download-contact-button">
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size="large"
          >
            <a
              href={`${BASE_URL}MedicalExamination/download-result?companyMedicalExaminationId=${medicalExaminationId}&email=${props.currentUser.email}`}
              target="_blank"
              style={{ color: 'white', marginLeft: 8 }}
            >
              Tải về kết quả khám
            </a>
          </Button>
        </div>
        <div className="download-contact-button">
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size="large"
          >
            <a
              href={`${BASE_URL}MedicalExamination/download-result-full?companyMedicalExaminationId=${medicalExaminationId}`}
              target="_blank"
              style={{ color: 'white', marginLeft: 8 }}
            >
              Tải về kết quả khám tổng hợp
            </a>
          </Button>
        </div>
      </Modal>
    </>
  );
};

// const mapStateToProps = (state: any) => {
//   return {
//     currentUser: (state.accounts && state.accounts.currentUser) || {},
//   };
// };

export default MedicalExaminationTable;
