import React, { ReactNode, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Tooltip,
  Table,
  Divider,
  Popconfirm,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  PlusOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { getAllAccountsAction } from 'store/actions/account';
import {
  createNewAccountsService,
  deleteAccountService,
} from 'services/account.services';
import { getHealthCareCenterInfoByAccount } from 'services/healthCareCenter.services';
import { updateInputAccountService } from './../../services/account.services';
import { EditAccountForm } from './../../components/Company/EditAccountForm';

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const ManageAccount: React.FC<any> = (props) => {
  const [newAccountModal, setNewAccountModal] = useState(false);
  const [error, setError] = useState('');
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [healthCareCenter, setHealthCareCenter] = useState<any>(null);
  const [editAccountModal, setEditAccountModal] = useState(false);
  const [currentEditAccount, setCurrentEditAccount] = useState(undefined);
  const [loadingModal, setLoadingModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAccountsAction());
    gethealthCareCenter();
  }, []);
  const gethealthCareCenter = async () => {
    const healthCareCenterInfo = await getHealthCareCenterInfoByAccount();
    setHealthCareCenter(healthCareCenterInfo?.data);
  };

  const columns: any = [
    {
      title: 'Địa chỉ email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: 'Chức năng',
      key: 'action',
      align: 'right',
      render: (_: string, record: any) => (
        <>
          <Tooltip title="Chỉnh sửa">
            <Button
              shape="circle"
              style={{ marginRight: '5px' }}
              icon={<EditOutlined style={{ fontSize: 18 }} />}
              onClick={(e) => {
                setCurrentEditAccount(record);
                setEditAccountModal(true);
              }}
            />
          </Tooltip>
          <Popconfirm
            title="Bạn có muốn xóa người này không?"
            onConfirm={() => {
              deleteAccountService(record.accountId).then((response: any) => {
                dispatch(getAllAccountsAction());
              });
            }}
            okText="Có"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            cancelText="Không"
          >
            <Tooltip title="Xóa">
              <Button
                shape="circle"
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                onClick={(e) => {}}
              />
            </Tooltip>
          </Popconfirm>
        </>
      ),
    },
  ];

  const onFinish = (e: any) => {
    const data: any[] = [];
    const { accounts } = e;
    const { currentUser } = props;

    accounts.map((account: any) =>
      data.push({
        healthCareCenterId: currentUser.healthCareCenterId,
        email: account,
      }),
    );
    setLoadingModal(true);
    createNewAccountsService(data)
      .then((response: any) => {
        if (response.status === 200) {
          setNewAccountModal(false);
          setError('');
          dispatch(getAllAccountsAction());
        }
      })
      .catch(({ response }) => {
        if (response && response.staus !== 200 && response.data)
          setError(response.data);
      })
      .finally(() => setLoadingModal(false));
    //setNewAccountModal(false);
  };
  const handleEditAccountSubmit = (e: any) => {
    setLoadingModal(true);
    updateInputAccountService({
      ...e,
      accountId: currentEditAccount.accountId,
    })
      .then(({ status, data }) => {
        if (status === 200) {
          dispatch(getAllAccountsAction());
          setCurrentEditAccount(undefined);
          setEditAccountModal(false);
          setError('');
        }
      })
      .catch((e) => {})
      .finally(() => setLoadingModal(false));
  };
  const getEditAccountInitialValues = () => {
    return currentEditAccount ? currentEditAccount : {};
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={props.accounts}
        pagination={{ position: ['bottomCenter'] }}
      />
      <Divider />
      <Button
        type="primary"
        shape="round"
        onClick={() => setNewAccountModal(true)}
      >
        Thêm tài khoản
      </Button>
      <Modal
        visible={newAccountModal}
        style={{ maxHeight: 500, overflow: 'auto' }}
        onCancel={() => {
          setNewAccountModal(false);
          setError('');
        }}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          name="new_account_form"
          onFinish={onFinish}
          initialValues={{ accounts: [''] }}
        >
          <Form.List name="accounts">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label="Địa chỉ email"
                    required={true}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          type: 'email',
                          message: 'Vui lòng nhập địa chỉ email.',
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="email" style={{ width: '60%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        style={{ paddingLeft: 10 }}
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Thêm địa chỉ email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          {error && (
            <Form.Item style={{ marginBottom: 0 }}>
              <Text type="danger" className="center">
                {error}
              </Text>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              loading={loadingModal}
            >
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={editAccountModal}
        onCancel={() => {
          setEditAccountModal(false);
          setError('');
        }}
        destroyOnClose={true}
        footer={null}
      >
        <EditAccountForm
          handleEditAccountSubmit={handleEditAccountSubmit}
          getEditAccountInitialValues={getEditAccountInitialValues}
          error={error}
          loadingModal={loadingModal}
          profile={currentEditAccount}
        />
      </Modal>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    accounts: (state.accounts && state.accounts.accounts) || [],
    currentUser: (state.accounts && state.accounts.currentUser) || null,
  };
};
export default connect(mapStateToProps)(withRouter(ManageAccount));
