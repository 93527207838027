import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers/';
import CompanyProfile from 'components/Company/ProfileForm';
const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Tạo công ty mới';
  });
  const { currentUser } = useSelector((state: RootState) => state.accounts);
  return (
    <div className="App">
      <CompanyProfile currentUser={currentUser}/>
    </div>
  );
};

export default Home;
