import { applyMiddleware, createStore, Store } from 'redux';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from 'store/reducers';
import { rootSaga } from './saga';
import { RootState } from './reducers'

const reduxPersistConfig: PersistConfig<any> = {
  key: 'application',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist:['layout']
};
const saga = createSagaMiddleware();

const pReducer = persistReducer<RootState>(reduxPersistConfig, rootReducer);

export const store: Store = createStore(
  pReducer,
  undefined,
  composeWithDevTools(applyMiddleware(saga)),
);
saga.run(rootSaga);
export const persistor = persistStore(store);
