import { put, call, all, fork, takeLatest } from 'redux-saga/effects';
import {
  getAllAccountsSuccessAction,
  getCurrentAccountSuccessAction,
} from 'store/actions/account';

import { ACCOUNT_CONSTANTS } from 'store/constants';
import {
  getAllAccountsService,
  getCurrentAccountService,
} from './../../services/account.services';
import moment from 'moment';

function* getAllAccounts() {
  try {
    const response = yield call(getAllAccountsService);

    if (response && response.data) {
      response.data.forEach((account: any) => {
        account.createdDate = moment(account.createdDate).format('DD/MM/YYYY');
      });
    }
    yield put(getAllAccountsSuccessAction(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* getCurrentAccount() {
  try {
    const response = yield call(getCurrentAccountService);
    yield put(getCurrentAccountSuccessAction(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* watchOnApp() {
  yield takeLatest(ACCOUNT_CONSTANTS.GET_ALL_ACCOUNTS, getAllAccounts);
  yield takeLatest(ACCOUNT_CONSTANTS.GET_CURRENT_ACCOUNT, getCurrentAccount);
}

export default function* accountSagas() {
  yield all([fork(watchOnApp)]);
}
