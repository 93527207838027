import { all, fork } from 'redux-saga/effects';
import accountSagas from './sagas/accountSagas';
import appSaga from './sagas/app';
import companiesSaga from './sagas/company';

export const rootSaga = function* root() {
  yield all([fork(companiesSaga)]);
  yield all([fork(appSaga)]);
  yield all([fork(accountSagas)]);
};
