import React, { useEffect } from 'react';

import MedicalExaminationTable from 'components/MedicalExamination/MedicalExaminationTable';
const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Tạo công ty mới';
  });
  return (
    <div className="App">
      <MedicalExaminationTable/>
    </div>
  );
};

export default Home;
