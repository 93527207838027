import _ from 'lodash';

export const checkAdminPermission = (role: any) => {
  if (!role) return;

  let adminRoles = ['admin'];

  if (adminRoles.includes(role.toLowerCase())) return true;

  return false;
};

export const ADMIN = 'ADMIN';
export const INPUT = 'INPUT';
