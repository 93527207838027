import React, { useEffect } from 'react';

import CompaniesTable from 'components/Company/CompaniesTable';
const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Danh sách công ty';
  });
  return (
    <div className="App">
      <CompaniesTable/>
    </div>
  );
};

export default Home;
