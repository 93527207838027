import BasicLayout from 'layouts/BasicLayout';
import LoginLayout from 'layouts/LoginLayout';
import Home from 'pages/home';
import Login from 'pages/login';
import CompanyCreate from 'pages/createCompany';
import CompanyList from 'pages/companyList';
import CompanyDetail from './../pages/companyDetail/index';
import MedicalExamination from './../pages/medicalExamination/index';
import Employee from './../pages/employee/index';
import InputMedicalExam from './../pages/inputMedicalExam/index';
import InputAccount from '../pages/manageAccount/manageAccount';
import CreateTemplate from './../pages/createTemplate/index';
import { ADMIN, INPUT } from 'utils/permissions';
import NotFound from 'pages/notFound';
// import PdfView from './../pages/pdfViewer/index'
import Account from './../pages/account';
import ChangePassword from './../pages/changePassword/index';
import Templates from './../pages/templates/templates';
import Contacts from './../pages/contact/contact';

export const routes = [
  {
    path: '/',
    component: CompanyList,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/company',
    component: CompanyList,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/company/create',
    component: CompanyCreate,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
    permissions: [ADMIN],
  },
  {
    path: '/company/detail/:id',
    component: CompanyDetail,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/company/employee/:id',
    component: CompanyDetail,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/medical-examination',
    component: MedicalExamination,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
    permissions: [ADMIN],
  },
  {
    path: '/medical-examination-employee/:id',
    component: Employee,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/medical-exam-result/:employeeId',
    component: InputMedicalExam,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/create-template/:id',
    component: CreateTemplate,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
    permissions: [ADMIN],
  },
  {
    path: '/manage-account',
    component: InputAccount,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
    permissions: [ADMIN],
  },
  {
    path: '/account',
    component: Account,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/account/change-password',
    component: ChangePassword,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/templates',
    component: Templates,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/contact',
    component: Contacts,
    authorized: true,
    layout: BasicLayout,
    showLeftSideBar: true,
  },
  {
    path: '/login',
    component: Login,
    layout: LoginLayout,
  },
  {
    path: '*',
    component: NotFound,
    layout: LoginLayout,
  },
];
