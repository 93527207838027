import React from 'react';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';

import { ILoginProps } from 'models/props/loginProps';
import { ILoginStates } from 'models/states/loginStates';
import LoginForm from './../../components/LoginForm/index';

class Login extends React.Component<ILoginProps, ILoginStates> {
  constructor(props: ILoginProps) {
    super(props);
    this.state = {};
    document.title = 'Đăng nhập';
  }
  componentDidMount() {
    localStorage.clear();
  }

  onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  render() {
    return <LoginForm onFinishFailed={this.onFinishFailed} />;
  }
}

export default withTranslation()(withRouter(Login));
