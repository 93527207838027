import { COMPANY_CONSTANTS } from 'store/constants';
import { AppActionTypes } from '../actions/app/type';
import { APP_CONSTANTS } from '../constants';
import _ from 'lodash';
import { handleCompanyCatogories } from './../../utils/helps';

interface AppInterface {
  title: string;
  menu: string;
  categories: any;
  companyCategoriesList: any;
  companyCategories: any;
}

const initialState: AppInterface = {
  title: 'TDHDM',
  menu: '',
  categories: [],
  companyCategories: [],
  companyCategoriesList: [],
};

export const app = (state = initialState, action: any) => {
  const { companyCategories } = state;
  switch (action.type) {
    case APP_CONSTANTS.SET_PAGE_TITLE:
    case APP_CONSTANTS.SET_MENU:
      return { ...state, ...{ ...action.payload } };
    case APP_CONSTANTS.GET_CATEGORIES_SUCCESS:
      let categories = action.payload;
      const subCategories = _.uniq(categories.map((c: any) => c.categoryLevel))
        .sort()
        .reverse();
      subCategories.forEach((level: any) => {
        if (level !== 1) {
          categories
            .filter((s: any) => s.categoryLevel === level - 1)
            .forEach((item: any) => {
              item.subCategories = categories.filter(
                (f: any) =>
                  f.categoryLevel === level &&
                  f.categoryParentId &&
                  f.categoryParentId === item.masterCategoryId,
              );
            });
        }
      });
      categories = categories.filter((s: any) => s.categoryLevel === 1);
      return {
        ...state,
        categories,
        masterCategories: action.payload,
      };

    case APP_CONSTANTS.GET_COMPANY_CATEGORIES_SUCCESS:
      return {
        ...state,
        companyCategoriesList: handleCompanyCatogories(action.data),
        companyCategories: action.data,
      };

    case APP_CONSTANTS.ADD_NEW_CATEGORY_DETAIL:
      companyCategories
        .find(
          (s: any) => s.companyCategoryId === action.payload.companyCategoryID,
        )
        ?.companyCategoryDetails.push(action.payload);
      return {
        ...state,
        companyCategories,
        companyCategoriesList: handleCompanyCatogories(companyCategories),
      };

    case APP_CONSTANTS.UPDATE_NEW_CATEGORY_DETAIL_DESCRIPTION:
      const category = companyCategories.find(
        (s: any) => s.companyCategoryId === action.payload.companyCategoryID,
      );

      const detail = category?.companyCategoryDetails?.find(
        (d: any) =>
          d.companyCategoryDetailsId ===
          action.payload.companyCategoryDetailsId,
      );

      if (detail) {
        detail.detailDescription = action.payload.detailDescription;
      }

      return {
        ...state,
        companyCategories,
        companyCategoriesList: handleCompanyCatogories(companyCategories),
      };

    case COMPANY_CONSTANTS.CHECK_COMPANY_EXAMMINATION_RESULT_SUCCESS:
      let hasResult =
        action.payload && action.payload.hasResult
          ? action.payload.hasResult
          : false;
      return {
        ...state,
        hasResult: hasResult,
      };

    default:
      return state;
  }
};
