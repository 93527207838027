import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import FileDownload from 'js-file-download';
import {
  Table,
  Collapse,
  Tabs,
  Button,
  Card,
  Upload,
  Modal,
  Form,
  Input,
  Tooltip,
  Descriptions,
  notification,
  Alert,
  Steps,
  Spin,
  Space,
  Result,
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { getCategoriesAction } from 'store/actions/app';
import Category from './components/category';
import {
  ConsoleSqlOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  ForwardOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  downloadInvalidUploadMedicalExaminationUpload,
  getMedicalExaminationByIdService,
} from '../../services/medicalExaminationServices';
import { getEmployeeListByMedicalExaminationId } from '../../services/employee.services';
import { getCompanyProfileByIdService } from '../../services/companyServices';
import { api } from '../../utils/apiServices';
import { v4 as uuidv4 } from 'uuid';
import {
  createCategoryTemplateService,
  updateCategoryTemplateService,
} from '../../services/category.services';
import {
  checkExamminationResultAction,
  getCompanyCategoriesAction,
} from '../../store/actions/company/index';
import { couldStartTrivia } from 'typescript';
import LeftSideBar from 'components/Layout/LeftSideBar';
import moment from 'moment';

const BASE_URL = process.env.REACT_APP_API_URL;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Step } = Steps;
const { Dragger } = Upload;
const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
  formLayout: 'horizontal',
};

const rowSelection = {};

class CreateTemplate extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentStep: 0,
      fields: [],
      selectedRows: [],
      requiredFields: [],
      employeeButtonLoading: false,
      isAlreadyHaveEmployee: false,
      isModalVisible: false,
      addCategoryButtonLoading: false,
      selectedCategoryName: '',
      selectedCategoryKey: '',
      isCreatedTemplate: false,
      fetchedCategory: false,
      medicalExaminationDetail: {},
      companyName: '',
      uploadKQXN: false,
      uploadMedicalResultResponseVisible: false,
      responseUploadMedicalResult: {},
      currentUploadStep: 0,
    };
    document.title = 'Mẫu nhập liệu';
  }

  columns = [
    {
      title: 'Danh mục khám',
      dataIndex: 'name',
      className: 'input-category-header',
    },
    {
      title: '',
      key: 'action',
      align: 'right' as const,
      render: (_: any, record: any) =>
        record.isCustomField ? (
          <>
            <Tooltip title="Kết quả khám">
              <Button
                shape="circle"
                icon={
                  <DeleteOutlined
                    style={{ fontSize: 18 }}
                    onClick={() => {
                      this.handleDeleteCustomCategory(record);
                    }}
                  />
                }
              />
            </Tooltip>
          </>
        ) : (
          <></>
        ),
    },
  ];

  componentDidMount() {
    this.getEmployeeList();
    this.props.dispatch(getCategoriesAction());

    this.props.dispatch(
      checkExamminationResultAction({ id: this.props.match.params.id }),
    );

    this.getMedicalExaminationList(this.props.match.params.id);
  }

  getMedicalExaminationList = async (examinationId: any) => {
    const medicalExaminationDetail: any = await getMedicalExaminationByIdService(
      examinationId,
    );
    if (medicalExaminationDetail?.data) {
      let companyProfileId = medicalExaminationDetail.data.companyProfileId;

      this.setState({
        medicalExaminationDetail: medicalExaminationDetail.data,
        currentUploadStep:
          medicalExaminationDetail.data.uploadStep === 'upload_medical_exam'
            ? 1
            : 0,
      });

      let companyInfo = companyProfileId
        ? await getCompanyProfileByIdService(companyProfileId)
        : null;
      if (companyInfo?.data) {
        this.setState({
          companyName: companyInfo.data.companyName,
        });
      }
    }
  };

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.categories.length && !this.state.fetchedCategory) {
      const fields: any[] = [];
      let requiredFields: any[] = [];
      nextProps.categories.forEach((category: any, index: number) => {
        const convertedFields = this.convertFromCategoryToField(
          category,
          index,
        );
        fields.push(convertedFields);
        const filteredRequiredFields = convertedFields.subFields.filter(
          (item: any) => {
            if (item.isRequired) {
              return item;
            }
          },
        );
        if (filteredRequiredFields.length) {
          requiredFields = [...requiredFields, ...filteredRequiredFields];
        }
      });
      this.setState(
        {
          fields: fields,
          selectedRows: requiredFields,
          requiredFields,
          fetchedCategory: this.state.fields.length,
        },
        () => {
          if (this.state.fields.length !== 0) {
            this.props.dispatch(
              getCompanyCategoriesAction({ id: this.props.match.params.id }),
            );
          }
        },
      );
    }
    if (
      nextProps.companyCategories.length &&
      this.props.companyCategories !== nextProps.companyCategories &&
      this.state.fetchedCategory
    ) {
      let fields: any[] = [];
      let categoryList = JSON.parse(JSON.stringify(this.state.fields));
      nextProps.companyCategories.forEach((category: any, index: number) => {
        const convertedFields = this.convertFromCategoryToField(
          category,
          index,
        );
        fields = fields.concat(convertedFields);
      });
      this.state.requiredFields.forEach((item: any) => {
        const requiredCategory = fields.find(
          (selection: any) => selection.key === item.key,
        );
        if (!requiredCategory) {
          fields.push(item);
        }
      });
      fields.forEach((item) => {
        if (!item.key) {
          item.key = `customCategory_${uuidv4()}`;
          const customCategoryParent = categoryList.find(
            (category: any) => item.parentCategoryKey === category.key,
          );
          if (customCategoryParent) {
            customCategoryParent.subFields.push(item);
          }
        }
      });
      this.setState((state: any) => ({
        selectedRows: fields,
        fields: categoryList,
        isCreatedTemplate: true,
      }));
    }
  }

  getEmployeeList = async () => {
    this.setState({ employeeButtonLoading: true });
    try {
      let employeeList: any = await getEmployeeListByMedicalExaminationId({
        companyMedicalExaminationID: this.props.match.params.id,
      });
      if (employeeList.data && employeeList.data.employeeProfile.length) {
        this.setState({ isAlreadyHaveEmployee: true });
      }
    } finally {
      this.setState({ employeeButtonLoading: false });
    }
  };

  onSelect = (
    record: any,
    selected: any,
    selectedRows: any,
    nativeEvent: any,
  ) => {
    //Disable item cho các item check default từ đầu.
    // if (record.isRequired) {
    if (record.isHide) {
      return;
    }

    let selectedRowsState = [...this.state.selectedRows];
    if (selected) {
      const index = selectedRowsState.findIndex(
        (s: any) => s.key === record.key,
      );
      if (index === -1) {
        selectedRowsState.push(record);
      }
      const parentCategory = this.state.fields.find(
        (item: any) => item.key === record.categoryParentId,
      );
      const linkCategory =
        parentCategory?.subFields.filter(
          (item: any) => item.categoryLinkID === record.key,
        ) || [];
      linkCategory.forEach((item: any) => {
        const linkCategoryIndex = selectedRowsState.findIndex(
          (i: any) => i.key === item.key,
        );
        if (linkCategoryIndex === -1) {
          selectedRowsState.push(item);
        }
      });
    } else {
      const index = selectedRowsState.findIndex(
        (s: any) => s.key === record.key,
      );
      if (index > -1) {
        selectedRowsState.splice(index, 1);
      }
      const parentCategory = this.state.fields.find(
        (item: any) => item.key === record.categoryParentId,
      );
      const linkCategory =
        parentCategory?.subFields.filter(
          (item: any) => item.categoryLinkID === record.key,
        ) || [];
      linkCategory.forEach((item: any) => {
        selectedRowsState = selectedRowsState.filter(
          (i: any) => item.key !== i.key,
        );
      });
    }
    this.setState({ selectedRows: selectedRowsState });
  };

  onSelectAll = (selected: boolean, selectedRows: any, changeRows: any) => {
    const selectedRowsState = this.state.selectedRows;

    if (selected) {
      changeRows.forEach((row: any) => {
        selectedRowsState.push(row);
      });
    } else {
      changeRows.forEach((row: any) => {
        const index = selectedRowsState.findIndex(
          (s: any) => s.key === row.key && !row.isRequired,
        );
        if (index > -1) {
          selectedRowsState.splice(index, 1);
        }
      });
    }
    this.setState({ selectedRows: selectedRowsState });
  };

  //Hàm này define data cho vào từng field trong bảng category
  convertFromCategoryToField = (category: any, index: number): any => {
    const result: any = {
      key: category.masterCategoryId,
      name: category.categoryName,
      price: category.categoryPrice,
      categoryParentId: category.categoryParentId,
      categoryLinkID: category.categoryLinkID,
      categorySkipId: category.categorySkipId,
      isRequired: category.isRequired,
      order: category.order,
      isCustomField: category.isCustomField || false,
      parentCategoryKey: category.parentCategoryKey,
      checked: false,
      subFields: [],
      details: [],
      isHide: category.isHide,
    };

    if (category.subCategories) {
      category.subCategories.forEach((subCategoiry: any, subIndex: number) => {
        result.subFields.push(
          this.convertFromCategoryToField(subCategoiry, subIndex),
        );
      });
    }
    return result;
  };

  onSaveTemplate = async () => {
    const { masterCategories, match } = this.props;
    const data: any = {};
    this.setState({ savingTemplate: true });
    data.companyMedicalExaminationId = match.params.id;
    data.data = [];
    this.state.selectedRows.forEach((row: any) => {
      if (row.categoryParentId) {
        let category: any;
        if (row.isCustomField) {
          category = row;
        } else {
          category = masterCategories.find(
            (s: any) => s.masterCategoryId === row.key,
          );
        }

        let isDataNotIncludedParentCategory: boolean = true;
        if (
          !row.isCustomField &&
          category &&
          category.categoryParentId &&
          data.data.find(
            (s: any) => s.masterCategoryId === category.categoryParentId,
          )
        ) {
          isDataNotIncludedParentCategory = false;
        }
        if (
          row.isCustomField &&
          category &&
          category.parentCategoryKey &&
          data.data.find(
            (s: any) => s.masterCategoryId === category.parentCategoryKey,
          )
        ) {
          isDataNotIncludedParentCategory = false;
        }

        if (isDataNotIncludedParentCategory) {
          let masterParentCategory = masterCategories.find((s: any) => {
            if (category.parentCategoryKey) {
              return s.masterCategoryId === category.parentCategoryKey;
            }
            return s.masterCategoryId === category.categoryParentId;
          });

          data.data.push({
            companyCategoryId: uuidv4(),
            categoryName: masterParentCategory.categoryName,
            categoryLevel: masterParentCategory.categoryLevel,
            categoryParentId: null,
            categoryPrice: 0,
            masterCategoryId: masterParentCategory.masterCategoryId,
            companyMedicalExaminationId: data.companyMedicalExaminationId,
            order: masterParentCategory.order,
            categoryType: masterParentCategory.categoryType,
            hasClassification: masterParentCategory.hasClassification,
            isCollapsed: masterParentCategory.isCollapsed,
            gender: masterParentCategory.gender,
            isHide: masterParentCategory.isHide,
            //companyCategoryDetailModels: [],
          });
        }
        const parentCategory = data.data.find((s: any) => {
          if (category.parentCategoryKey) {
            return s.masterCategoryId === category.parentCategoryKey;
          }
          return s.masterCategoryId === category.categoryParentId;
        });
        if (row.isCustomField) {
          data.data.push({
            companyCategoryId: uuidv4(),
            categoryName: row.name,
            categoryLevel: 2,
            categoryParentId: parentCategory.companyCategoryId,
            categoryPrice: 0,
            masterCategoryId: null,
            companyMedicalExaminationId: data.companyMedicalExaminationId,
            order: 999,
            categoryType: null,
            hasClassification: null,
            isCollapsed: true,
            gender: null,
            isCustomField: true,
            parentCategoryKey: row.parentCategoryKey,
            //companyCategoryDetailModels: [],
          });
        } else {
          data.data.push({
            companyCategoryId: uuidv4(),
            categoryName: category.categoryName,
            categoryLevel: category.categoryLevel,
            categoryParentId: parentCategory.companyCategoryId,
            categoryPrice: 0,
            masterCategoryId: row.key,
            companyMedicalExaminationId: data.companyMedicalExaminationId,
            order: category.order,
            categoryType: category.categoryType,
            hasClassification: category.hasClassification,
            isCollapsed: category.isCollapsed,
            gender: category.gender,
            isHide: category.isHide,
            //companyCategoryDetailModels: [],
          });
        }
      }
    });

    let filteredData = data.data.filter((i: any) => !i.isHide);

    if (this.state.isCreatedTemplate) {
      const result: any = await updateCategoryTemplateService(data);
      if (!result.error) {
        this.redirectToCompanyProfilePage();
      }
    } else {
      const result: any = await createCategoryTemplateService(data);
      if (!result.error) {
        this.redirectToCompanyProfilePage();
        this.setState({ isCreatedTemplate: true });
      }
    }

    this.setState({ savingTemplate: false });
  };

  uploadEmployeeListFile = async (e: any) => {
    try {
      this.setState({ employeeButtonLoading: true });
      let companyMedicalExaminationData = await getMedicalExaminationByIdService(
        this.props.match.params.id,
      );
      let companyProfileId = null;
      let taxReferenceNumber = null;
      if (companyMedicalExaminationData?.data) {
        companyProfileId = companyMedicalExaminationData.data.companyProfileId;
      }

      let companyInfo = companyProfileId
        ? await getCompanyProfileByIdService(companyProfileId)
        : null;
      if (companyInfo?.data) {
        taxReferenceNumber = companyInfo.data.taxReferenceNumber;
      }
      let formData = new FormData();
      formData.append('FormFile', e.file);
      formData.append('companyProfileId', companyProfileId);
      formData.append('filename', e.file.name);
      formData.append('taxReferenceNumber', taxReferenceNumber);
      formData.append(
        'CompanyMedicalExaminationID',
        this.props.match.params.id,
      );

      let response = await api.postForm(
        `${process.env.REACT_APP_API_URL}EmployeeProfiles/upload-employee`,
        formData,
      );
      if (response.data) {
        this.setState({ isAlreadyHaveEmployee: true });
      }
    } finally {
      this.setState({ employeeButtonLoading: false });
    }
  };

  uploadMedicalResultFile = async (e: any) => {
    try {
      await this.setState({ uploadKQXN: true });
      let formData = new FormData();
      formData.append('FormFile', e.file);
      formData.append(
        'CompanyMedicalExaminationID',
        this.props.match.params.id,
      );
      formData.append('Email', this.props.currentUser.email);

      this.setState({
        uploadingMedicalResultMessageVisible: true,
      });

      let response = await api.postForm(
        `${process.env.REACT_APP_API_URL}MedicalExamination/upload-medical-examination-result`,
        formData,
      );
      this.setState({
        uploadKQXN: false,
        responseUploadMedicalResult: response.data,
        uploadMedicalResultResponseVisible: true,
        uploadingMedicalResultMessageVisible: false,
      });
    } catch {
      this.setState({
        uploadKQXN: false,
      });
      notification.error({ message: 'Đã có lỗi xảy ra. Vui lòng thử lại.' });
    }
  };

  handleCloseModal = () => {
    this.setState({ isModalVisible: false });
  };

  handleCreateCategory = (newCategory: any) => {
    const categoryFields: any = JSON.parse(JSON.stringify(this.state.fields));
    let currentCategory = categoryFields.find(
      (item: any) => item.key === this.state.selectedCategoryKey,
    );
    const category = {
      key: `customCategory_${uuidv4()}`,
      name: newCategory.categoryName,
      price: null,
      categoryParentId: this.state.selectedCategoryKey,
      categoryLinkID: null,
      categorySkipId: null,
      isRequired: false,
      order: null,
      checked: false,
      subFields: [],
      details: [],
      deletable: true,
      isCustomField: true,
      parentCategoryKey: this.state.selectedCategoryKey,
    };
    currentCategory.subFields.push(category);

    // this.setState({fields:[]},() => {this.setState({fields:fields})})
    this.setState({ fields: categoryFields });
    this.onSelect(category, true, null, null);
    this.handleCloseModal();
  };

  handleDeleteCustomCategory = (category: any) => {
    const categoryFields: any = JSON.parse(JSON.stringify(this.state.fields));
    let currentCategory = categoryFields.find(
      (item: any) => item.key === category.parentCategoryKey,
    );
    currentCategory.subFields = currentCategory.subFields.filter(
      (item: any) => item.key !== category.key,
    );
    this.setState({ fields: categoryFields });
    this.onSelect(category, false, null, null);
  };

  redirectToCompanyProfilePage = () => {
    let companyProfileUrl =
      '/company/detail/' + this.state.medicalExaminationDetail.companyProfileId;

    window.location.href = companyProfileUrl;
  };

  redirectToEmployeeListPage = () => {
    let employeeListPage = `/medical-examination-employee/${this.props.match.params.id}`;

    window.location.href = employeeListPage;
  };

  handleClickOkUploadMedicalResultResponseModal = () => {
    this.setState({ uploadMedicalResultResponseVisible: false });
  };

  handleClickCancelUploadMedicalResultResponseModal = () => {
    this.setState({ uploadMedicalResultResponseVisible: false });
  };

  handleClickDownloadInvalidDataFileUploadMedicalResultResponseModal = async () => {
    const { responseUploadMedicalResult } = this.state;
    try {
      var responseFile = await downloadInvalidUploadMedicalExaminationUpload(
        responseUploadMedicalResult.invalidDataFilePath,
      );
      FileDownload(
        responseFile.data,
        responseUploadMedicalResult.invalidDataFilePath,
      );
    } catch {
      notification.error({
        message:
          'Có sự cố xảy ra khi tải file. Vui lòng liên hệ quản trị viên để được giải quyết.',
      });
    }
    this.setState({
      uploadMedicalResultResponseVisible: false,
      responseUploadMedicalResult: {},
    });
  };

  render() {
    const { categories } = this.props;
    const {
      fields,
      selectedRows,
      medicalExaminationDetail,
      uploadMedicalResultResponseVisible,
      uploadingMedicalResultMessageVisible,
      responseUploadMedicalResult,
      currentUploadStep,
    } = this.state;

    const self = this;
    const uploadProps = {
      name: 'employeeFile',
      accept: '.xlsx,.csv',
      showUploadList: false,
      customRequest(data: any) {
        self.uploadEmployeeListFile(data);
      },
    };

    const uploadMedicalInputProps = {
      name: 'medicalInputFile',
      accept: '.xlsx,.csv',
      showUploadList: false,
      customRequest(data: any) {
        self.uploadMedicalResultFile(data);
      },
    };

    const getListFooterButton = () => {
      const buttons = [];
      buttons.push(
        <Button
          key="back"
          onClick={this.handleClickCancelUploadMedicalResultResponseModal}
        >
          Đóng
        </Button>,
      );
      if (
        responseUploadMedicalResult &&
        responseUploadMedicalResult.invalidDataFilePath
      ) {
        buttons.push(
          <Button
            key="link"
            type="primary"
            onClick={
              this
                .handleClickDownloadInvalidDataFileUploadMedicalResultResponseModal
            }
          >
            Tải file
          </Button>,
        );
      }

      return buttons;
    };

    const getUploadStepComponent = () => {
      switch (currentUploadStep) {
        case 1:
          return (
            <Dragger {...uploadMedicalInputProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click vào đây để tải file kết quả xét nghiệm
              </p>
            </Dragger>
          );
        default:
          return (
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click vào đây để tải file danh sách người lao động
              </p>
            </Dragger>
          );
      }
    };

    return (
      <>
        <div className="App">
          {' '}
          <Descriptions
            className="employee-info-container "
            title={this.state.companyName} //"Tên khách hàng"
            extra={
              <>
                {this.state.isAlreadyHaveEmployee && (
                  <Button
                    onClick={this.redirectToEmployeeListPage}
                    shape="round"
                    style={{ marginLeft: 20 }}
                    type="primary"
                    icon={<ForwardOutlined />}
                  >
                    Danh sách người lao động
                  </Button>
                )}

                <Button
                  onClick={this.redirectToCompanyProfilePage}
                  shape="round"
                  style={{ marginLeft: 20 }}
                  type="primary"
                  icon={<ForwardOutlined />}
                >
                  Tới trang thông tin
                </Button>
              </>
            }
          >
            <Descriptions.Item label="Kỳ khám">
              <b>{medicalExaminationDetail.medicalExaminationName}</b>
            </Descriptions.Item>
            <Descriptions.Item label="Ngày bắt đầu">
              <b>
                {medicalExaminationDetail.startDate
                  ? moment(medicalExaminationDetail.startDate).format(
                      'DD/MM/yyyy',
                    )
                  : ''}
              </b>
            </Descriptions.Item>
            <Descriptions.Item label="Ngày kết thúc">
              <b>
                {medicalExaminationDetail.endDate
                  ? moment(medicalExaminationDetail.endDate).format(
                      'DD/MM/yyyy',
                    )
                  : ''}
              </b>
            </Descriptions.Item>
            <Descriptions.Item
              className="current-input-quantity"
              label="Số hiện tại"
            >
              <p className="current-employee-input-quantity">
                {medicalExaminationDetail.currentInputEmployeeQuantity}
              </p>
              <p className="current-employee-quantity">
                &nbsp;/&nbsp; {medicalExaminationDetail.employeeQuantity}
              </p>
            </Descriptions.Item>
          </Descriptions>
          <Card style={{ marginBottom: 16 }}>
            <Steps current={currentUploadStep} style={{ marginBottom: 16 }}>
              <Step title="Tải lên danh sách người lao động"></Step>
              <Step title="Tải lên kết quả xét nghiệm" />
            </Steps>
            <div className="steps-content" style={{ marginBottom: 16 }}>
              {getUploadStepComponent()}
            </div>
            <Button
              loading={
                this.state.employeeButtonLoading || this.state.uploadKQXN
              }
              shape="round"
              style={{ marginLeft: 20 }}
              type="primary"
            ></Button>
            {/* </div> */}
          </Card>
          <Card
            title={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ maxWidth: 400 }}>
                  <h2 style={{ fontSize: 21, marginBottom: 0 }}>
                    Tạo form Nhập liệu
                  </h2>
                </div>
                {/* <div
                  style={{
                    maxWidth: 400,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'nowrap',
                  }}
                >
                  <div>
                    <Upload {...uploadMedicalInputProps}>
                      <Button
                        disabled={!this.state.isCreatedTemplate}
                        shape="round"
                        type="primary"
                      >
                        Tải lên kết quả khám
                      </Button>
                    </Upload>
                    <Button
                      shape="round"
                      disabled={!this.state.isCreatedTemplate}
                      style={{ marginLeft: 20 }}
                      type="primary"
                    >
                      <a
                        href={`${BASE_URL}MedicalExamination/download-result?companyMedicalExaminationId=${this.props.match.params.id}`}
                        target="_blank"
                      >
                        {' '}
                        Tải về kết quả khám{' '}
                      </a>
                    </Button>
                  </div>
                </div> */}
              </div>
            }
          >
            <Tabs type="card">
              {fields.map((field: any, index: number) => (
                <TabPane tab={field.name} key={index}>
                  <Table
                    rowSelection={{
                      selectedRowKeys: this.state.selectedRows.map(
                        (s: any) => s.key,
                      ),
                      type: 'checkbox',
                      onSelect: this.onSelect,
                      onSelectAll: this.onSelectAll,
                    }}
                    key={index}
                    columns={this.columns}
                    dataSource={field.subFields}
                    pagination={false}
                    rowClassName={(record: any) =>
                      record.categoryLinkID || record.isHide ? 'hidden-row' : ''
                    }
                  />
                  <Button
                    style={{ marginTop: 8 }}
                    key="button"
                    onClick={() =>
                      this.setState({
                        isModalVisible: true,
                        selectedCategoryKey: field.key,
                        selectedCategoryName: field.name,
                      })
                    }
                    className="btn-add-detail-category background-purple"
                    shape="round"
                    // disabled={isDisabledPanel}
                    icon={<PlusCircleOutlined />}
                  >
                    Thêm
                  </Button>
                  ,
                </TabPane>
              ))}
            </Tabs>
          </Card>
          <div
            style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}
          >
            <Button
              shape="round"
              type="primary"
              onClick={this.onSaveTemplate}
              disabled={this.props.hasResult}
              loading={this.state.savingTemplate}
            >
              {this.state.isCreatedTemplate ? 'Cập nhật' : 'Xác nhận'}
            </Button>
          </div>
        </div>
        <Form
          {...formLayout}
          id="createAdditionalCategory"
          name="create-additional-category"
          fields={fields}
          onFinish={this.handleCreateCategory}
        >
          <Modal
            title={`Thêm mục khám mới cho  ${this.state.selectedCategoryName}`}
            closable
            cancelText="Hủy"
            okText="Thêm mục khám"
            // onOk={handleCreateMedicalExamination}
            onCancel={this.handleCloseModal}
            visible={this.state.isModalVisible}
            footer={[
              <Button
                key="back"
                onClick={this.handleCloseModal}
                loading={this.state.addCategoryButtonLoading}
              >
                Hủy
              </Button>,
              <Button
                form="createAdditionalCategory"
                key="submit"
                type="primary"
                htmlType="submit"
                loading={this.state.addCategoryButtonLoading}
              >
                Thêm mục khám
              </Button>,
            ]}
          >
            <Form.Item
              label={`Mục khám mới`}
              name={'categoryName'}
              rules={[{ required: true, message: 'Vui lòng nhập mục khám' }]}
            >
              <Input placeholder="Tên mục khám" />
            </Form.Item>
          </Modal>
        </Form>

        <Modal
          visible={uploadingMedicalResultMessageVisible}
          title="Thông báo"
          footer={null}
          closable={false}
          // onOk={this.handleClickOkUploadMedicalResultResponseModal}
          // onCancel={this.handleClickCancelUploadMedicalResultResponseModal}
          // footer={getListFooterButton()}
        >
          {/* <Alert
            message="Quá trình upload đang diễn ra"
            description={`Đang trong quá trình upload kết quả xét nghiệm. Xin đừng tắt trình duyệt. Kết quả sẽ được thông báo sau ít phút.`}
            type="info"
            action={
              <Space direction="vertical">
                <Button size="small" type="primary">
                  Accept
                </Button>
                <Button size="small" danger type="ghost">
                  Decline
                </Button>
              </Space>
            }
          /> */}

          <Result
            status="info"
            title="Đang trong quá trình upload kết quả xét nghiệm. Xin đừng tắt trình duyệt. Kết quả sẽ được thông báo sau ít phút."
            extra={<Spin size="large" />}
          />
        </Modal>

        <Modal
          visible={uploadMedicalResultResponseVisible}
          title="Kết quả"
          onOk={this.handleClickOkUploadMedicalResultResponseModal}
          onCancel={this.handleClickCancelUploadMedicalResultResponseModal}
          footer={getListFooterButton()}
        >
          {responseUploadMedicalResult?.message ? (
            <Alert
              message="Thành công"
              description={responseUploadMedicalResult?.message}
              type="success"
              showIcon
            />
          ) : (
            <></>
          )}
          {responseUploadMedicalResult?.totalSuccessRecord ? (
            <Alert
              message="Thành công"
              description={`Đã cập nhật thông tin xét nghiệm thành công cho ${responseUploadMedicalResult?.totalSuccessRecord} / ${responseUploadMedicalResult?.totalRecord}.`}
              type="success"
              showIcon
            />
          ) : (
            <></>
          )}
          {responseUploadMedicalResult?.totalDuplicateRecord ? (
            <Alert
              message="Thông tin trùng lặp"
              description={`Đã phát hiện ${responseUploadMedicalResult?.totalDuplicateRecord} / ${responseUploadMedicalResult?.totalRecord} bị trùng thông tin. Vui lòng tải file bên dưới để biết thêm thông tin chính xác.`}
              type="error"
              showIcon
            />
          ) : (
            <></>
          )}

          {responseUploadMedicalResult?.totalNotFoundRecord ? (
            <Alert
              message="Thông tin không chính xác"
              description={`Đã phát hiện ${responseUploadMedicalResult?.totalNotFoundRecord} / ${responseUploadMedicalResult?.totalRecord} thông tin không chính xác. Vui lòng tải file bên dưới để biết thêm thông tin chính xác.`}
              type="error"
              showIcon
            />
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const {
    categories,
    masterCategories,
    companyCategories,
    hasResult,
  } = state.app;

  return {
    categories: categories || [],
    masterCategories: masterCategories || [],
    companyCategories: companyCategories || [],
    hasResult: hasResult || false,
    currentUser: state.accounts.currentUser || {},
  };
};

export default connect(mapStateToProps)(withRouter(CreateTemplate));
