import React, { ReactNode, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Upload,
  Modal,
  Card,
  Tooltip,
  Table,
  Divider,
  Popconfirm,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  InboxOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
//import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import {
  createCompanyService,
  updateCompanyService,
} from '../../services/companyServices';
import { getItemLocalStorage } from 'utils/localStorege';
import { api } from '../../utils/apiServices';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import {
  deleteAccountAction,
  getAllAccountsAction,
} from 'store/actions/account';
import {
  createNewAccountsService,
  deleteAccountService,
  updateCurrentAccountService,
} from 'services/account.services';
import { getHealthCareCenterInfoByAccount } from 'services/healthCareCenter.services';
import { checkAdminPermission } from 'utils/permissions';
import { updateInputAccountService } from './../../services/account.services';
import { EditAccountForm } from './../../components/Company/EditAccountForm';
const BASE_URL = process.env.REACT_APP_API_URL;

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const Account: React.FC<any> = (props) => {
  const [error, setError] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  const getEditAccountInitialValues = () => {
    return props.currentUser;
  };
  const handleEditAccountSubmit = (e) => {
    setLoadingModal(true);
    updateCurrentAccountService({
      ...e,
      accountId: props.currentUser.accountId,
    })
      .then(({ status, data }) => {
        if (status === 200) {
          setError('');
        }
      })
      .catch((e) => {})
      .finally(() => setLoadingModal(false));
  };
  return (
    <>
      <Card style={{ marginBottom: 16 }} title="Thông tin tài khoản">
        <EditAccountForm
          handleEditAccountSubmit={handleEditAccountSubmit}
          getEditAccountInitialValues={getEditAccountInitialValues}
          error={error}
          loadingModal={loadingModal}
          profile={props.currentUser}
        />
      </Card>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    currentUser: (state.accounts && state.accounts.currentUser) || null,
  };
};
export default connect(mapStateToProps)(withRouter(Account));
