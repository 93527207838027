import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import vi from './locales/vi';
import en from './locales/en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(XHR)
  .init({
    resources: { vi, en },
    lng: 'vi',
    ns: ['common'],
    // keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: 'vi',
    load: 'currentOnly',
    react: {
      wait: true,
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
