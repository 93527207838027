import { COMPANY_CONSTANTS } from 'store/constants';

export function getCompanyDetailByIdAction(payload: any): any {
  return {
    type: COMPANY_CONSTANTS.GET_DETAIL_BY_ID,
    payload,
  };
}

export function getCompanyDetailByIdSuccessAction(data: any): any {
  return {
    type: COMPANY_CONSTANTS.GET_DETAIL_BY_ID_SUCCESS,
    data,
  };
}

export function getCompanyCategoriesAction(payload: any): any {
  return {
    type: COMPANY_CONSTANTS.GET_COMPANY_CATEGORIES,
    payload,
  };
}

export function getCompanyCategoriesSuccessAction(data: any): any {
  return {
    type: COMPANY_CONSTANTS.GET_COMPANY_CATEGORIES_SUCCESS,
    data,
  };
}

export function checkExamminationResultAction(payload: any): any {
  return {
    type: COMPANY_CONSTANTS.CHECK_COMPANY_EXAMMINATION_RESULT,
    payload,
  };
}

export function checkExamminationResultSuccessAction(payload: any): any {
  return {
    type: COMPANY_CONSTANTS.CHECK_COMPANY_EXAMMINATION_RESULT_SUCCESS,
    payload,
  };
}
