import React, { ReactNode, useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { loginService } from 'services/loginServices';
import { setItemLocalStorage } from './../../utils/localStorege';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { loginWithOtpService } from './../../services/loginServices';

const layout = {
  // labelCol: { span: 4 },
  wrapperCol: { span: 24, offset: 4 },
};
const tailLayout = {
  wrapperCol: { span: 16 },
};

interface LoginFormProps {
  onFinishFailed: any;
  children?: ReactNode;
}
const LoginForm: React.FC<LoginFormProps> = (props) => {
  const [loginState, setLoginState] = useState('input_username_password');
  const [loginResponse, setLoginResponse] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {}, [loginErrorMessage]);

  const onFinish = async (values: any) => {
    setLoginLoading(true);

    let currentLoginKey = values.username + moment().valueOf();
    try {
      const response = await loginService(
        values.username,
        values.password,
        currentLoginKey,
      );
      if (response.data && response.data.actionNeeded) {
        setLoginErrorMessage('');
        setLoginResponse(response.data);
        setLoginState(response.data.actionNeeded);
      } else if (response.data && response.data.accessToken) {
        setItemLocalStorage('access_token', response.data.accessToken);
        setItemLocalStorage('current_login_key', currentLoginKey);
        setItemLocalStorage('expire_at', response.data.expireAt);
        history.push('/company');
        setLoginErrorMessage('');
      }
      else if(response.data && response.data.errorCode && response.data.errorCode == 'ACCOUNT_EXPIRED'){
          setLoginErrorMessage('Tài khoản đã hết hạn. Vui lòng liên hệ quản lý hệ thống để gia hạn.');
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        setLoginErrorMessage(error.response.data);
      } else {
        setLoginErrorMessage('Có lỗi xảy ra.');
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const onSubmitOtp = async (values: any) => {
    setLoginLoading(true);
    let currentLoginKey = loginResponse?.userName + moment().valueOf();
    try {
      const response = await loginWithOtpService(
        loginResponse?.userName,
        values.otp,
        currentLoginKey,
      );
      setItemLocalStorage('access_token', response.data.accessToken);
      setItemLocalStorage('current_login_key', currentLoginKey);
      setItemLocalStorage('expire_at', response.data.expireAt);
      history.push('/company');
      setLoginErrorMessage('');
    } catch (error) {
      if (error && error.response && error.response.data) {
        setLoginErrorMessage(error.response.data);
      } else {
        setLoginErrorMessage('Có lỗi xảy ra.');
      }
    } finally {
      setLoginLoading(false);
    }
  };
  const usernameRequired = t('login:usernameRequired');
  const passwordRequired = t('login:passwordRequired');

  if (loginState == 'input_otp') {
    return (
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmitOtp}
        scrollToFirstError={true}
        style={{ marginTop: '50px' }}
      >
        <Text>
          Vui lòng nhập mã OTP được gửi vào email đăng ký của bạn. Nếu không tìm
          thấy trong hộp thư đến xin hãy kiểm tra trong thư mục spam
        </Text>
        <Divider />
        <Form.Item
          label="OTP"
          name="otp"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mã OTP được gửi vào email của bạn',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Divider />
        {loginErrorMessage && (
          <>
            <Text type="danger">{loginErrorMessage}</Text> <Divider />
          </>
        )}

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            loading={loginLoading}
          >
            Xác nhận
          </Button>
        </Form.Item>
      </Form>
    );
  }
  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={props.onFinishFailed}
      scrollToFirstError={true}
      style={{ marginTop: '50px' }}
    >
      <Form.Item
        label={t('login:username')}
        name="username"
        rules={[{ required: true, message: usernameRequired }]}
      >
        <Input
        // style={{ width: '50%' }}
        />
      </Form.Item>

      <Form.Item
        label={t('login:password')}
        name="password"
        style={{ fontSize: '20' }}
        rules={[{ required: true, message: passwordRequired }]}
      >
        <Input.Password
        // style={{ width: '50%' }}
        />
      </Form.Item>

      {/* <Form.Item
        {...tailLayout}
        style={{ marginBottom: 5 }}
        name="remember"
        valuePropName="checked"
      >
        <Checkbox>{t('login:rememberMe')}</Checkbox>
      </Form.Item>*/}

      <Form.Item {...tailLayout} style={{ }}>
        <Text type="danger" >
          {loginErrorMessage}
        </Text>
      </Form.Item> 

      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          loading={loginLoading}
        >
          {t('button:login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
