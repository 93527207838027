import React, { ReactNode, useState, Dispatch } from 'react';
import { Layout, Menu } from 'antd';
import { RootState } from '../../store/reducers/';
import { LayoutActionTypes } from '../../store/actions/layout/type';
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch, connect } from 'react-redux';
import logo from '../../resources/img/logo.jpg';
import { Link, useHistory } from 'react-router-dom';
import { getCurrentAccountSuccessAction } from 'store/actions/account';
import { checkAdminPermission } from './../../utils/permissions';
const { Header } = Layout;
const { SubMenu } = Menu;

interface HeaderProps {
  children?: ReactNode;
  currentUser?: any;
}
const HeaderLayout: React.FC<HeaderProps> = (props) => {
  const history = useHistory();
  const { sidebar } = useSelector((state: RootState) => state.layout);
  const layoutDispatch = useDispatch<Dispatch<LayoutActionTypes>>();
  const handleLogout = () => {
    localStorage.clear();
    layoutDispatch(getCurrentAccountSuccessAction(null));
    history.push('/login');
  };
  const handleToggleSidebar = (value: boolean) => {
    layoutDispatch({ type: 'TOGGLE_SIDEBAR', payload: value });
  };
  return (
    <Header
      className="site-layout-background menu-header"
      style={{ padding: 0, zIndex: 2 }}
      hasSider
    >
      <div className="menu-button">
        <span
          className="menu-button--icon"
          onClick={() => {
            handleToggleSidebar(!sidebar);
          }}
        >
          {sidebar ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </span>
      </div>
      <div className="logo">
        {/* <img
          src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"

          alt="Logo"
        ></img> */}
        <img src={logo} alt="Logo"></img>
      </div>
      <h2 className="logo">TDHDM</h2>
      <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={['2']}
        style={{ float: 'right', marginLeft: 'auto', marginRight: '15px' }}
      >
        {/* <Menu.Item key="1">Danh sách công ty</Menu.Item>
        <Menu.Item key="2">Danh sách tài khoản</Menu.Item> */}
        <SubMenu
          key="3"
          icon={<UserOutlined />}
          title={'Xin chào! ' + props.currentUser.fullName}
        >
          <Menu.Item key="3.1">
            <Link to="/account">Thông tin tài khoản</Link>
          </Menu.Item>
          <Menu.Item key="3.3">
            <Link to="/account/change-password">Đổi mật khẩu</Link>
          </Menu.Item>
          <Menu.Item onClick={handleLogout} key="3.2">
            Đăng xuất
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  );
};

export default connect()(HeaderLayout);
