import React, { ReactElement, useEffect } from 'react';
import { Layout, Skeleton } from 'antd';

import Header from '../components/Layout/Header';
import LeftSideBar from 'components/Layout/LeftSideBar';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { getCurrentAccountAction } from 'store/actions/account';
const { Content } = Layout;

export interface BasicLayoutProps {
  children: ReactElement;
  authorized: boolean;
  showLeftSideBar: boolean;
}

const BasicLayout: React.FC<any> = (props) => {
  const { match, location, history, currentUser } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser === null) {
      dispatch(getCurrentAccountAction());
    }
  }, [match, location, history]);
  if (currentUser === null) {
    return <Skeleton />;
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
      className="main-container"
      id="basic-layout"
    >
      <Header currentUser={currentUser} />
      <Layout>
        {props.showLeftSideBar && <LeftSideBar currentUser={currentUser} />}
        <Layout style={{ padding: '0' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  return {
    currentUser: (state.accounts && state.accounts.currentUser) || null,
  };
};
export default connect(mapStateToProps)(withRouter(BasicLayout));
