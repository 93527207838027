import React, { ReactNode, useEffect, useState } from 'react';
import { Form, Input, Button, Upload, Card } from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  InboxOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
//import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import {
  createCompanyService,
  updateCompanyService,
} from './../../services/companyServices';
import { getItemLocalStorage } from 'utils/localStorege';
import { api } from '../../utils/apiServices';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { checkAdminPermission } from 'utils/permissions';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
  formLayout: 'horizontal',
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

interface CompanyProfileProps {
  children?: ReactNode;
  companyInfo?: any;
  companyId?: any;
  currentUser?: any;
}

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const CompanyProfile: React.FC<CompanyProfileProps> = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [taxReferenceNumber, setTaxReferenceNumber] = useState('');
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [fields, setFields] = useState();
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    let fieldsData: any = [];

    var obj = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getItemLocalStorage('access_token')}`,
      },
    };
  
    if (props && props.companyId) {
      fetch(`${process.env.REACT_APP_API_URL}company/${props.companyId}`, obj)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result) {
              let contacts =
                result.companyContact === ''
                  ? []
                  : JSON.parse(result.companyContact);

              setContactList(contacts);

              let contactListFieldsData =
                contacts
                  ?.map((item: any, index: number) => {
                    return [
                      {
                        name: ['contacts', index, 'fullName'],
                        value: item.FullName,
                      },
                      { name: ['contacts', index, 'email'], value: item.Email },
                      {
                        name: ['contacts', index, 'phoneNumber'],
                        value: item.PhoneNumber,
                      },
                    ];
                  })
                  .flat() || [];

              fieldsData = [
                { name: ['companyName'], value: result.companyName },
                { name: ['companyAddress'], value: result.companyAddress },
                {
                  name: ['taxReferenceNumber'],
                  value: result.taxReferenceNumber,
                },
                ...contactListFieldsData,
              ];
              setFields(fieldsData);
            }
          },
          (error) => {
            console.log(error);
          },
        );
    }


  }, []);

  const history = useHistory();
  const onFinishFailed = () => {
    setSubmitLoading(false);
  };
  const onFinish = (e: any) => {
    setSubmitLoading(true);
    if (!!props.companyInfo) {
      let updateData = {
        ...e,
        companyProfileId: props.companyInfo.companyProfileId,
        isDeleted: false,
      };

      updateCompanyService(props.companyId, updateData)
        .then((respose) => respose.data)
        .catch((e) => { })
        .finally(() => {
          setSubmitLoading(false);
        });
    } else {
      createCompanyService(e)
        .then((respose) => respose.data)
        .then((data: any) => {
          history.push(`/company/detail/${data.data.companyProfileId}`);
        })
        .catch((e) => { })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };
  const normFile = (e: any) => {
    if (e.file) {
      return e.file;
    }
  };

  const uploadContactFile = (e: any) => {
    let formData = new FormData();
    formData.append('FormFile', e.file);
    formData.append('TaxReferenceNumber', taxReferenceNumber);
    formData.append('filename', e.file.name);
    let response = api.postForm(
      `${process.env.REACT_APP_API_URL}company/validate-file`,
      formData,
    );
  };

  const setTaxReferenceNumberValue = (e: any) => { };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{
        contacts: contactList.map(() => {
          return { fullName: '' };
        }),
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError={true}
      fields={fields}
    >
      <Card
        className="company-create-card"
        title={
          <p className="company-profile--card__header">Thông tin Khách hàng</p>
        }
        style={{ width: '100%', margin: '0 auto 32px auto' }}
      >
        <Form.Item
          label="Khách hàng"
          name="companyName"
          rules={[{ required: true, message: 'Vui lòng nhập tên khách hàng' }]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="Mã số thuế"
          name="taxReferenceNumber"
          rules={[{ required: true, message: 'Vui lòng nhập mã số thuế' }]}
        >
          <Input
            onChange={(e) => {
              setTaxReferenceNumber(e.target.value);
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label="Địa chỉ" name="companyAddress">
          <Input style={{ width: '100%' }} />
        </Form.Item>
      </Card>

      {checkAdminPermission(props.currentUser.accountRole) && (
        <Form.Item className="company-create-form-button">
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            loading={submitLoading}
          >
            {!!props.companyInfo ? 'Cập nhật' : 'Tạo mới'}
          </Button>
        </Form.Item>
      )}

      {checkAdminPermission(props.currentUser.accountRole) && (
        <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <React.Fragment key={key}>
                  <Card
                    className="company-create-card"
                    title={
                      <p className="company-profile--card__header">
                        Người liên hệ {name + 1}
                      </p>
                    }
                    extra={
                      <div style={{ overflowY: 'visible' }}>
                        <MinusCircleOutlined
                          style={{
                            fontSize: 25,
                            right: props.companyInfo ? '2vw' : '7vw',
                            position: 'relative',
                            color: 'red',
                          }}
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      </div>
                    }
                    style={{ width: '100%', margin: '0 auto 32px auto' }}
                  >
                    <Form.Item
                      {...restField}
                      label={`Họ và Tên`}
                      name={[name, 'fullName']}
                      fieldKey={[fieldKey, 'fullName']}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập đầy đủ họ tên',
                        },
                      ]}
                    >
                      <Input placeholder="Họ và Tên" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={`Số điện thoại`}
                      name={[name, 'phoneNumber']}
                      fieldKey={[fieldKey, 'phoneNumber']}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số điện thoại',
                        },
                      ]}
                    >
                      <Input placeholder="Số điện thoại" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={`Địa chỉ email`}
                      name={[name, 'email']}
                      fieldKey={[fieldKey, 'email']}
                      rules={[
                        {
                          type: 'email',
                          message: 'Địa chỉ email không đúng định dạng',
                        },
                      ]}
                    >
                      <Input placeholder="Địa chỉ email" type="email" />
                    </Form.Item>
                  </Card>
                </React.Fragment>
              ))}{' '}
              {checkAdminPermission(props.currentUser.accountRole) && (
                <Form.Item className="company-create-form-button">
                  <Button
                    type="dashed"
                    onClick={() => {
                      return add();
                    }}
                    shape="round"
                    icon={<PlusOutlined />}
                  >
                    Thêm người liên hệ
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      )}
      <Form.Item
        className="company-create-form-button"
        style={{ marginBottom: 0 }}
      >
        <Text type="danger" className="center"></Text>
      </Form.Item>
    </Form>
  );
};

export default CompanyProfile;
