import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import CompanyProfile from 'components/Company/ProfileForm';
import MedicalExaminationTable from 'components/MedicalExamination/MedicalExaminationTable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCompanyDetailByIdAction } from './../../store/actions/company/index';

class CompanyDetail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    document.title = 'Thông tin Khách hàng';
  }

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params && match.params.id) {
      dispatch(getCompanyDetailByIdAction({ id: match.params.id }));
    }
  }

  render() {
    return (
      <div className="App">
        <div style={{ padding: '32px 0 0 0' }}></div>
        <Row gutter={24}>
          <Col xs={24} sm={10}>
            {this.props.companyInfo !== null && (
              <CompanyProfile
                currentUser={this.props.currentUser}
                companyInfo={this.props.companyInfo}
                companyId={this.props.match.params.id}
              />
            )}
          </Col>
          <Col xs={24} sm={14}>
            <MedicalExaminationTable
              currentUser={this.props.currentUser}
              companyInfo={this.props.companyInfo}
              companyId={this.props.match.params.id}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentUser: (state.accounts && state.accounts.currentUser) || null,
    companyInfo: (state.company && state.company.companyDetail) || null,
  };
};
export default connect(mapStateToProps)(withRouter(CompanyDetail));
