import { put, call, all, fork, takeLatest } from 'redux-saga/effects';

import { APP_CONSTANTS } from 'store/constants';
import { getCategoriesServices } from './../../services/app.services';
import { getCategoriesSuccessAction } from './../actions/app/index';

function* getCategoriesSaga() {
  try {
    const response = yield call(getCategoriesServices);
    yield put(getCategoriesSuccessAction(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* checkExamminationResultSaga() {
  try {
    const response = yield call(getCategoriesServices);
    yield put(getCategoriesSuccessAction(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* watchOnApp() {
  yield takeLatest(APP_CONSTANTS.GET_CATEGORIES, getCategoriesSaga);
}

export default function* appSaga() {
  yield all([fork(watchOnApp)]);
}
