import { AxiosResponse } from 'axios';
import { api } from '../utils/apiServices';

export const loginService = (
  username: string,
  password: string,
  currentloginkey: string,
): Promise<AxiosResponse<any>> => {
  return api.post('account/login', {
    username,
    password,
    currentloginkey,
  });
};

export const checkCurrentLoginKeyService = (
  userName: string,
  currentloginkey: string,
): Promise<AxiosResponse<any>> => {
  return api.post('account/check-current-login-key', {
    userName,
    currentloginkey,
  });
};

export const loginWithOtpService = (
  username: string,
  otp: string,
  currentloginkey: string,
): Promise<AxiosResponse<any>> => {
  return api.post('account/otp', {
    username,
    otp,
    currentloginkey,
  });
};
