import 'antd/dist/antd.css';
import './resources/scss/main.scss';

import { persistor, store } from 'store';

import { App } from './pages/app';
import React from 'react';
import { render } from 'react-dom';
import './i18n';

const targetHTMLElement = document.getElementById('root');

render(<App store={store} persistor={persistor} />, targetHTMLElement);
