import React from 'react';
import { Button, Table, Row, Col } from 'antd';
const BASE_URL = process.env.REACT_APP_API_URL;

const MedicalExaminationTable: React.FC = () => {
  const templateData = [
    {
      key: '1',
      templates: 'Template Upload Danh sách người Khám sức khỏe',
      downloadLink: `${BASE_URL}EmployeeProfiles/get-file-template`,
    },
    {
      key: '2',
      templates: 'Template Upload Kết quả Xét nghiệm',
      downloadLink: `${BASE_URL}MedicalExamination/download-medical-input-template`,
    },
  ];

  const columns: any = [
    {
      title: 'TEMPLATES',
      dataIndex: 'templates',
      key: 'templates',
    },
    {
      title: '',
      key: 'download',
      align: 'right' as const,
      render: (_: string, record: any) => (
        <>
          <Button className="background-purple text-white" shape="round">
            <a href={record.downloadLink} target="_blank">
              Tải về
            </a>
          </Button>
        </>
      ),
    },
  ];

  const columnsDocument: any = [
    {
      title: 'IN HỒ SƠ',
      dataIndex: 'documents',
      key: 'documents',
    },
    {
      title: '',
      key: 'download',
      align: 'right' as const,
      render: (_: string, record: any) => (
        <>
          <Button className="background-purple text-white" shape="round">
            <a href={record.downloadLink} target="_blank">
              Tải về
            </a>
          </Button>
        </>
      ),
    },
  ];

  const templateDocuments = [
    {
      key: '1',
      documents: 'Phiếu Khám sức khỏe định kỳ',
      downloadLink: `${BASE_URL}MedicalExamination/get-template-ksk`,
    },
    {
      key: '2',
      documents: 'Phiếu Siêu âm bụng',
      downloadLink: `${BASE_URL}MedicalExamination/get-template-sieu-am-bung`,
    },
    {
      key: '3',
      documents: 'Phiếu Siêu âm tuyến Giáp',
      downloadLink: `${BASE_URL}MedicalExamination/get-template-sieu-am-tuyen-giap`,
    },
    {
      key: '4',
      documents: 'Phiếu Siêu âm Vú',
      downloadLink: `${BASE_URL}MedicalExamination/get-template-sieu-am-vu`,
    },
    {
      key: '5',
      documents: 'Phiếu chụp X-Quang tim, phổi',
      downloadLink: `${BASE_URL}MedicalExamination/get-template-x-quang`,
    },
    {
      key: '6',
      documents: 'Phiếu Xét nghiệm',
      downloadLink: `${BASE_URL}MedicalExamination/get-template-xet-nghiem`,
    },
    {
      key: '7',
      documents: 'Phiếu Xét nghiệm tế bào Âm đạo',
      downloadLink: `${BASE_URL}MedicalExamination/get-template-xet-nghiem-am-dao`,
    },
  ];

  return (
    <>
      <Row style={{ justifyContent: 'center' }}>
        <Col span={18}>
          <Table
            className={'table-templates'}
            columns={columns}
            dataSource={templateData}
            pagination={false}
          />
        </Col>

        <Col span={18}>
          <Table
            className={'table-templates'}
            columns={columnsDocument}
            dataSource={templateDocuments}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default MedicalExaminationTable;
