import { AxiosResponse } from 'axios';
import { api } from '../utils/apiServices';
export const createCategoryTemplateService = (
  data: any,
): Promise<AxiosResponse<any>> => {
  return api.post('category', data);
};

export const updateCategoryTemplateService = (
  data: any,
): Promise<AxiosResponse<any>> => {
  return api.post('update-template', data);
};
