import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist';
import { Provider } from 'react-redux';
import React, { useEffect, Dispatch } from 'react';
import Routes from 'routes';
import { Store } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { LayoutActionTypes } from '../../store/actions/layout/type';

interface AppProps {
  store: Store;
  persistor: Persistor;
}

export const App: React.FC<AppProps> = ({ store, persistor }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
};
