import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = 'Không tìm thấy';
  });
  return <div className="App">Không tìm thấy</div>;
};

export default NotFound;
