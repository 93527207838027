import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import moment from 'moment';

import React from 'react';
import { routes } from './data';
import { formatRoutes } from '../utils/helps';
import { connect } from 'react-redux';

import { getCurrentAccountService } from 'services/account.services';
import NotFound from './../pages/notFound/index';

const newRoutes = formatRoutes(routes) || [];
const Routes: React.FC<any> = (props) => {
  const { currentUser } = props;
  return (
    <Router>
      <Switch>
        {newRoutes.map((route, i) => RouteWithSubRoutes(route, i, currentUser))}
      </Switch>
    </Router>
  );
};

function RouteWithSubRoutes(route: any, index: number, currentUser) {
  return (
    <Route
      strict
      key={index}
      path={route.path}
      exact
      render={(props) => {
        if (route.authorized) {
          if (checkAuth.isAuthenticated()) {
            if (checkPermission(route.permissions, currentUser)) {
              return (
                <route.layout
                  authorized={route.authorized}
                  showLeftSideBar={route.showLeftSideBar}
                >
                  <route.component exact {...props} routes={route.routes} />
                </route.layout>
              );
            } else {
              return <NotFound />;
            }
          } else {
            return <Redirect to="/login" />;
          }
        }
        return (
          <route.layout
            authorized={route.authorized}
            showLeftSideBar={route.showLeftSideBar}
          >
            <route.component exact routes={route.routes} />
          </route.layout>
        );
      }}
    />
  );
}

const checkPermission = (permissions: string[], currentUser): boolean => {
  if (!permissions) {
    return true;
  }
  if (!currentUser) {
    return true;
  }
  if (permissions.includes(currentUser.accountRole.toUpperCase())) {
    return true;
  }
  return false;
};

export const checkAuth = {
  async isAuthenticated() {
    // //Call to DB to check current log in key
    getCurrentAccountService()
      .then((response: any) => {
        if (response && response.data) {
          let currentLoginKey = response.data.currentLoginKey;

          if (
            !localStorage.getItem('access_token') ||
            !localStorage.getItem('expire_at') ||
            moment().utc().diff(localStorage.getItem('expire_at')) > 0 ||
            currentLoginKey !== localStorage.getItem('current_login_key')
          ) {
            window.location.href = '/login';
          }
        }
      })
      .catch((error: any) => {
        window.location.href = '/login';
      });
    return localStorage.getItem('access_token') &&
      moment().utc().diff(localStorage.getItem('expire_at')) > 0
      ? true
      : false;
  },
};

const mapStateToProps = (state) => {
  const { currentUser } = state.accounts;
  return { currentUser };
};
export default connect(mapStateToProps)(Routes);
