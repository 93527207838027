export const CATEGORY_CONSTANTS = {
  CHIEU_CAO: 'chiều cao',
  CAN_NANG: 'cân nặng',
  BMI: 'bmi',
  THI_LUC: 'thị lực',
  THINH_LUC: 'thính lực',
  MAT_TRAI_KHONG_KINH: 'mắt trái không kính',
  MAT_PHAI_KHONG_KINH: 'mắt phải không kính',
  MAT_TRAI_CO_KINH: 'mắt trái có kính',
  MAT_PHAI_CO_KINH: 'mắt phải có kính',
  TAI_TRAI_NOI_THUONG: 'tai trái nói thường',
  TAI_TRAI_NOI_THAM: 'tai trái nói thầm',
  TAI_PHAI_NOI_THUONG: 'tai phải nói thường',
  TAI_PHAI_NOI_THAM: 'tai phải nói thầm',
};
