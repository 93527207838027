import { ACCOUNT_CONSTANTS } from '../constants';

interface IAccountReducerInterface {
  accounts: any[];
  isLoading: boolean;
  error: string;
  currentUser: any;
}
const initialState: IAccountReducerInterface = {
  accounts: [],
  isLoading: false,
  error: '',
  currentUser: null,
};

export const accountReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case ACCOUNT_CONSTANTS.GET_ALL_ACCOUNTS_SUCCESS:
      return { ...state, accounts: action.payload };
    case ACCOUNT_CONSTANTS.GET_CURRENT_ACCOUNT_SUCCESS:
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
};
