import { ACCOUNT_CONSTANTS } from 'store/constants';

export function getAllAccountsAction(): any {
  return {
    type: ACCOUNT_CONSTANTS.GET_ALL_ACCOUNTS,
  };
}

export function getAllAccountsSuccessAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.GET_ALL_ACCOUNTS_SUCCESS,
    payload,
  };
}

export function getCurrentAccountAction(): any {
  return {
    type: ACCOUNT_CONSTANTS.GET_CURRENT_ACCOUNT,
  };
}

export function getCurrentAccountSuccessAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.GET_CURRENT_ACCOUNT_SUCCESS,
    payload,
  };
}

export function createNewAccountsAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.CREATE_NEW_ACCOUNT,
    payload,
  };
}

export function createNewAccountsSuccessAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.CREATE_NEW_ACCOUNT_SUCCESS,
    payload,
  };
}
export function createNewAccountsFailedAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.CREATE_NEW_ACCOUNT_FAILED,
    payload,
  };
}

export function deleteAccountAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.DELETE_ACCOUNT,
    payload,
  };
}

export function deleteAccountSuccessAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.DELETE_ACCOUNT_SUCCESS,
    payload,
  };
}
export function deleteAccountFailedAction(payload: any): any {
  return {
    type: ACCOUNT_CONSTANTS.DELETE_ACCOUNT_FAILED,
    payload,
  };
}
