import React from 'react';
import { Button, Table, Row, Col, Descriptions } from 'antd';
const BASE_URL = process.env.REACT_APP_API_URL;

const ContactLayout: React.FC = () => {
  return (
    <>
      <Row style={{ justifyContent: 'center' }}>
        <Descriptions title="Đội ngũ phát triển" layout="vertical">
          <Descriptions.Item label="Địa chỉ email liên hệ">
            td.hdm.jsc@gmail.com
          </Descriptions.Item>
        </Descriptions>
        ,
      </Row>
    </>
  );
};

export default ContactLayout;
