import { getCurrentAccountService } from './../services/account.services';
import axios, { AxiosRequestConfig } from 'axios';
import { handleStatusHttps } from './helps';
import { getItemLocalStorage } from './localStorege';

const BASE_URL = process.env.REACT_APP_API_URL;
const api = {
  getInstance: () => {
    return axios.create({
      baseURL: BASE_URL,
      timeout: 600000,
      headers: {
        Authorization: 'Bearer ' + getItemLocalStorage('access_token'),
      },
    });
  },

  get: (url: string, options?: AxiosRequestConfig | undefined) => {
    const defaultOptions = {
      method: 'get',
    };
    if (url.charAt(0) === '/') {
      url = url.slice(1, url.length);
    }
    options = Object.assign(defaultOptions, options, {});
    return api
      .getInstance()
      .get(url, options)
      .then((res) => res)
      .catch((err) => {
        if (err && err.response) handleStatusHttps(err.response.status);
        throw err;
      });
  },

  post: (url: string, data: any, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: 'post',
    };
    if (url.charAt(0) === '/') {
      url = url.slice(1, url.length);
    }
    options = Object.assign(defaultOptions, options, {});

    return api
      .getInstance()
      .post(url, data, options)
      .then((res) => res)
      .catch((err) => {
        //handleStatusHttps(err.response.status);
        throw err;
      });
  },

  postForm: (
    url: string,
    data: any,
    options?: AxiosRequestConfig | undefined,
  ) => {
    let defaultOptions = {
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10000000
    };
    if (url.charAt(0) === '/') {
      url = url.slice(1, url.length);
    }

    options = Object.assign(defaultOptions, options, {});

    return api
      .getInstance()
      .post(url, data, options)
      .then((res) => res)
      .catch((err) => {
        //handleStatusHttps(err.response.status);

        throw err;
      });
  },

  put: (url: string, data: any, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: 'put',
    };
    if (url.charAt(0) === '/') {
      url = url.slice(1, url.length);
    }
    options = Object.assign(defaultOptions, options, {});

    return api
      .getInstance()
      .put(url, data, options)
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  },

  patch: (
    url: string,
    data?: any,
    options?: AxiosRequestConfig | undefined,
  ) => {
    let defaultOptions = {
      method: 'patch',
    };
    if (url.charAt(0) === '/') {
      url = url.slice(1, url.length);
    }
    options = Object.assign(defaultOptions, options, {});

    return api
      .getInstance()
      .patch(url, data, options)
      .then((res) => res)
      .catch((err) => {
        //handleStatusHttps(err.response.status);
        throw err;
      });
  },

  delete: (url: string, options?: AxiosRequestConfig | undefined) => {
    let defaultOptions = {
      method: 'delete',
    };
    if (url.charAt(0) === '/') {
      url = url.slice(1, url.length);
    }
    options = Object.assign(defaultOptions, options, {});

    return api
      .getInstance()
      .delete(url, options)
      .then((res) => res)
      .catch((err) => {
        //handleStatusHttps(err.response.status);
        throw err;
      });
  },

  async isAuthenticated() {
    //Call to DB to check current log in key

    getCurrentAccountService()
      .then((response: any) => {
        let currentLoginKey =
          response && response.data ? response.data.currentLoginKey : '';
        if (
          localStorage.getItem('access_token') &&
          currentLoginKey === localStorage.getItem('current_login_key')
        ) {
          return true;
        } else return false;
      })
      .catch((error: any) => {
        window.location.href = '/login';
      });

    // return true;
    //return localStorage.getItem('access_token') ? true : false;
  },
};

// Request interceptor for API calls
api.getInstance().interceptors.request.use(
  async (config) => {
    config.headers = {
      headers: {
        Authorization: 'Bearer ' + getItemLocalStorage('access_token'),
      },
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
api.getInstance().interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      originalRequest._retry = true;
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export { api };
