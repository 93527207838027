import { COMPANY_CONSTANTS } from '../constants';

const initialState: any = {};

export const companyReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case COMPANY_CONSTANTS.GET_DETAIL_BY_ID_SUCCESS:
      return { ...state, companyDetail: action.data };

    case COMPANY_CONSTANTS.GET_COMPANY_CATEGORIES_SUCCESS:
      return { ...state, companyCategories: action.payload };

    default:
      return state;
  }
};
