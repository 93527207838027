import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { api } from '../utils/apiServices';
export const createMedicalExaminationService = (
  data: any,
): Promise<AxiosResponse<any>> => {
  return api.post(
    '/MedicalExamination/create-company-medical-examination',
    data,
  );
};

export const getMedicalExaminationService = (
  params: any,
): Promise<AxiosResponse<any>> => {
  return api.get(`/MedicalExamination`, { params });
};

export const downloadEmployeeMedicalResult = (
  data: any,
): Promise<AxiosResponse<any>> => {
  const options: AxiosRequestConfig = {
    responseType: 'blob',
  };
  return api.post(
    `/MedicalExamination/download-employee-medical-result`,
    data,
    options,
  );
};

export const downloadInvalidUploadMedicalExaminationUpload = (
  fileName: string,
): Promise<AxiosResponse<any>> => {
  const options: AxiosRequestConfig = {
    responseType: 'blob',
  };
  return api.get(
    `/MedicalExamination/get-error-medical-examination-upload-file/${fileName}`,
    options,
  );
};

export const getMedicalExaminationByIdService = (
  id: string,
): Promise<AxiosResponse<any>> => {
  return api.get(`/MedicalExamination/${id}`);
};

export const getMedicalExaminationByEmployeeIdService = (
  employeProfileId: string,
): Promise<AxiosResponse<any>> => {
  return api.get(
    `/get-exammination-info-from-employee-id?employeProfileId=${employeProfileId}`,
  );
};

export const updateMedicalExaminationService = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  return api.put(`/MedicalExamination/${id}`, data);
};

export const medicalExaminationResultServices = (
  request: any,
): Promise<AxiosResponse<any>> => {
  return api.post(`/MedicalExamination/medical-examination-result`, request);
};

export const deleteMedicalExaminationService = (
  id: string,
): Promise<AxiosResponse<any>> => {
  return api.delete(`MedicalExamination/${id}`);
};
