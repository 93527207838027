import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import {
  message,
  Steps,
  Form,
  Input,
  Button,
  Upload,
  Card,
  Checkbox,
  Radio,
  Col,
  Row,
  Tabs,
  Collapse,
  Table,
  InputNumber,
  Divider,
  DatePicker,
  Select,
  Affix,
  Descriptions,
  notification,
  Modal,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { Redirect } from 'react-router-dom';
import FileDownload from 'js-file-download';
import {
  getCategoriesAction,
  updateNewCategoryDetailDescriptionAction,
} from 'store/actions/app';
import { getEmployeeByEmployeeIdService } from './../../services/employee.services';
import { getCompanyCategoriesAction } from './../../store/actions/company/index';
import { addNewCategoryDetailAction } from './../../store/actions/app/index';
import {
  ArrowDownOutlined,
  EditOutlined,
  ForwardOutlined,
  PlusCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { CATEGORY_CONSTANTS } from 'store/constants/category.constants';
import { convertBmiValue } from 'utils/helps';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
  getMedicalExaminationByEmployeeIdService,
  medicalExaminationResultServices,
  downloadEmployeeMedicalResult,
  getMedicalExaminationByIdService,
} from 'services/medicalExaminationServices';
import moment from 'moment';
import EmployeeProfile from 'components/Employee/EmployeeForm';
import { getCompanyProfileByIdService } from 'services/companyServices';

const BASE_URL = process.env.REACT_APP_API_URL;

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;
var calcBmi = require('bmi-calc');

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

class MedicalExamInput extends React.Component<any, any> {
  medicalCategoryClassification = 'medical_category_classification_';

  constructor(props: any) {
    super(props);
    this.state = {
      fields: [],
      step: '0',
      disabledTabs: {},
      linkedTabs: {},
      chieuCao: 0,
      canNang: 0,
      bmi: 0,
      formRef: React.createRef(),
      employeeInfo: {},
      examminationInfo: {},
      loadingDownloadResult: false,
      isShowModalEditEmployeeProfile: false,
      companyName: '',
    };
    document.title = 'Trang nhập liệu';
  }

  componentDidMount() {
    const employeeId = this.props.match.params.employeeId;

    getEmployeeByEmployeeIdService(employeeId).then((response) => {
      if (response.data) {
        this.setState({ employeeInfo: response.data });

        if (
          response.data.companyMedicalExaminationId
          // &&
          // this.props.categories.length !== 0
        ) {
          this.props.dispatch(
            getCompanyCategoriesAction({
              id: response.data.companyMedicalExaminationId,
              gender: response.data.gender,
            }),
          );
        }

        getMedicalExaminationByIdService(
          response.data.companyMedicalExaminationId,
        ).then((medicalExaminationDetail) => {
          if (medicalExaminationDetail?.data) {
            let companyProfileId =
              medicalExaminationDetail.data.companyProfileId;

            this.setState({
              examminationInfo: medicalExaminationDetail.data,
            });

            if (companyProfileId) {
              getCompanyProfileByIdService(companyProfileId).then(
                (companyInfo) => {
                  if (companyInfo.data) {
                    this.setState({
                      companyName: companyInfo.data.companyName,
                    });
                  }
                },
              );
            }
          }
        });
      }
    });

    if (this.props.categories.length > 0) {
      this.intialInputForm();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.categories.length != this.props.categories.length) {
      this.intialInputForm();
    }
  }

  handleAddNewCategoryDetail = (categoryId: string, order: number) => {
    this.props.dispatch(
      addNewCategoryDetailAction({
        companyCategoryDetailsId: uuidv4(),
        detailDescription: '',
        companyCategoryID: categoryId,
        order,
        isAddNew: true,
      }),
    );
  };

  handleTabNavigation = (key: string) => {
    this.setState({ step: key.toString() });
  };

  handleChangeNewDetailDescription = (
    e: any,
    companyCategoryDetailsId: string,
    companyCategoryID: string,
  ) => {
    const { fields } = this.state;
    const field = fields.find((s: any) => s.name === companyCategoryID);
    if (!Array.isArray(field.value)) {
      field.value = [];
    }
    if (field.value.indexOf((s) => s === companyCategoryDetailsId) === -1) {
      field.value.push(companyCategoryDetailsId);
    }
    this.setState({ fields });

    this.props.dispatch(
      updateNewCategoryDetailDescriptionAction({
        companyCategoryDetailsId,
        companyCategoryID,
        detailDescription: e.target.value,
      }),
    );
  };
  onSaveMedicalInput = (e: any) => {
    const data: any[] = [];
    const request: any = {};
    const { employeeInfo, linkedTabs, fields } = this.state;
    const { companyCategories } = this.props;
    const requireTabs = {}
    for (const tab in linkedTabs) {
      requireTabs[tab] = !linkedTabs[tab].length
    }

    fields.forEach((field: any) => {
      const property = field.name;
      for (const tab in linkedTabs) {
        if(linkedTabs[tab].includes(field.name)) {
          if(field.value.length) {
            requireTabs[tab] = true
          }
        }
      }
      const tempCategoryResult: any = {
        employeeProfileId: employeeInfo.employeeProfileId,
        companyCategoryId: property,
        medicalExaminationResultDetails: [],
        categoryHealthClass: null,
      };
      if (!property.includes(this.medicalCategoryClassification)) {
        if (property !== 'BMI') {
          if (Array.isArray(field.value)) {
            field.value.forEach((element: any) =>
              tempCategoryResult.medicalExaminationResultDetails.push({
                detailDescription: element,
              }),
            );
          } else {
            tempCategoryResult.medicalExaminationResultDetails.push({
              detailDescription: field.value,
            });
          }

          //process with medical exam className
          if (
            fields.findIndex(
              (s) =>
                s.name ===
                `${this.medicalCategoryClassification}${tempCategoryResult.companyCategoryId}`,
            ) > -1
          ) {
            tempCategoryResult.categoryHealthClass = fields.find(
              (s) =>
                s.name ===
                `${this.medicalCategoryClassification}${tempCategoryResult.companyCategoryId}`,
            ).value;
          }
          data.push(tempCategoryResult);
        } else {
          request.bMI = e[property];
        }
      }
    });
    let isValidForm = true
    for (const requireTab in requireTabs) {
      if(!requireTabs[requireTab]) {
        isValidForm = false
        this.props.categories.forEach((category: any) => {
          const requiredCategory = category.subCategories.find(item => item.companyCategoryId === requireTab)
          if(requiredCategory) {
            message.error(`Cần điền đầy đủ thông tin phần ${requiredCategory.categoryName}`);
          }
        })
      }
    }
    if(!isValidForm) {
      return
    }
    request.data = data;
    request.companyCategoryDetails = [];
    companyCategories.forEach((element: any) => {
      request.companyCategoryDetails = request.companyCategoryDetails.concat(
        element.companyCategoryDetails.filter(
          (s: any) => s.isAddNew && s.detailDescription,
        ),
      );
    });

    medicalExaminationResultServices(request).then((response: any) => {
      const { examminationInfo } = this.state;
      window.location.href =
        '/medical-examination-employee/' +
        examminationInfo.companyMedicalExaminationId;
      //window.location.reload();
    });
  };

  handleCheckboxOnChange = (e: any, name: string) => {
    const { fields } = this.state;
    const field = fields.find((s: any) => s.name === name);
    if (e.target.checked) {
      if (!Array.isArray(field.value)) {
        field.value = [];
      }
      field.value.push(e.target.value);
    } else if (field.value.find((s: string) => s === e.target.value)) {
      field.value.splice(
        field.value.findIndex((s: string) => s === e.target.value),
        1,
      );
    }
    this.setState({ fields });
  };

  handleRadioOnChange = (e: any, name: string, parentCategoryId: string) => {
    const { companyCategories } = this.props;
    let { disabledTabs, linkedTabs, fields } = this.state;
    const category = companyCategories.find(
      (s: any) => s.companyCategoryId === name,
    );
    const detail = category.companyCategoryDetails.find(
      (s: any) => s.companyCategoryDetailsId === e.target.value,
    );

    const field = fields.find((s: any) => s.name === name);
    if (field && field.value) {
      const previousDetail =
        category.companyCategoryDetails &&
        category.companyCategoryDetails.find(
          (s: any) => s.companyCategoryDetailsId === field.value,
        );
      if (previousDetail) {
        if (
          previousDetail.makeDisable &&
          ['none', 'normal'].findIndex(
            (s: any) => s === previousDetail.makeDisable,
          ) > -1 &&
          previousDetail.disableTabs
        ) {
          const disabledTab = disabledTabs[parentCategoryId];
          const relativeTabs = JSON.parse(previousDetail.disableTabs);
          relativeTabs
            .filter(
              (s: string) => disabledTab.findIndex((d: string) => d === s) > -1,
            )
            .forEach((tabId: string) => {
              disabledTab.splice(
                disabledTab.findIndex((d: string) => d === tabId),
                1,
              );
            });
        }
        if (
          previousDetail.makeDisable &&
          previousDetail.makeDisable === 'abnormal' &&
          previousDetail.disableTabs
        ) {
          const linkedTab = linkedTabs[name];
          const relativeTabs = JSON.parse(previousDetail.disableTabs);
          relativeTabs
            .filter(
              (s: string) => linkedTab.findIndex((d: string) => d === s) > -1,
            )
            .forEach((tabId: string) => {
              linkedTab.splice(
                linkedTab.findIndex((d: string) => d === tabId),
                1,
              );
            });
        }
      }
    }
    field.value = e.target.value;

    [disabledTabs, linkedTabs] = this.processMakeTabDisable(
      detail,
      disabledTabs,
      linkedTabs,
      name,
      parentCategoryId,
    );

    if (detail.makeDisable) {
      const categoriesWithSameParent: any[] = companyCategories.filter(
        (c: any) =>
          c.categoryParentId === category.categoryParentId &&
          c.hasClassification,
      );
      switch (detail.makeDisable) {
        case 'none':
          categoriesWithSameParent.forEach((subCategory: any) => {
            const field = fields.find(
              (s: any) =>
                s.name ===
                `${this.medicalCategoryClassification}${subCategory.companyCategoryId}`,
            );
            if (field) {
              field.value = null;
            }
            this.handleClassificationChange(
              '0',
              `${this.medicalCategoryClassification}${subCategory.companyCategoryId}`,
            );
          });
          break;
        case 'normal':
          categoriesWithSameParent.forEach((subCategory: any) => {
            const field = fields.find(
              (s: any) =>
                s.name ===
                `${this.medicalCategoryClassification}${subCategory.companyCategoryId}`,
            );
            if (field) {
              field.value = '1';
            }
            this.handleClassificationChange(
              '1',
              `${this.medicalCategoryClassification}${subCategory.companyCategoryId}`,
            );
          });
          break;
        default:
          categoriesWithSameParent.forEach((subCategory: any) => {
            const field = fields.find(
              (s: any) =>
                s.name ===
                `${this.medicalCategoryClassification}${subCategory.companyCategoryId}`,
            );
            if (field) {
              field.value = '1';
            }
            this.handleClassificationChange(
              '1',
              `${this.medicalCategoryClassification}${subCategory.companyCategoryId}`,
            );
          });
          break;
      }
    }
    this.setState({ fields, disabledTabs, linkedTabs });
  };
  handleTextBoxOnChange = (e: any, name: string) => {
    const { fields } = this.state;
    const field = fields.find((s: any) => s.name === name);
    field.value = e.target.value;
    this.setState({ fields });
  };
  handleNumberboxOnChange = (e: number, name: string) => {
    const { fields } = this.state;
    const field = fields.find((s: any) => s.name === name);
    field.value = e;
    this.setState({ fields });
  };

  handleClassificationChange = (e: any, name: string) => {
    const { fields } = this.state;
    const field = fields.find((s: any) => s.name === name);
    if (field) {
      field.value = e;
    }
    this.state.formRef.current.setFieldsValue({
      [name]: e,
    });

    this.setState({ fields });
  };

  renderCustomMedicalExaminationOption = (
    category: any,
    categories: any,
    isDisabledPanel: boolean,
  ) => {
    if (
      category.categoryName &&
      category.categoryName.toLowerCase().indexOf(CATEGORY_CONSTANTS.THI_LUC) >
      -1
    ) {
      const leftEyesNonGlassesInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.MAT_TRAI_KHONG_KINH,
      );
      const rightEyesNonGlassesInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.MAT_PHAI_KHONG_KINH,
      );
      const leftEyesGlassesInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.MAT_TRAI_CO_KINH,
      );
      const rightEyesGlassesInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.MAT_PHAI_CO_KINH,
      );
      return (
        <div>
          <Row>
            <Col span={4}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <span>Không kính:</span>
              </div>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Mắt phải"
                name={rightEyesNonGlassesInputDetail?.companyCategoryId}
                key={rightEyesNonGlassesInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, rightEyesNonGlassesInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Mắt trái"
                name={leftEyesNonGlassesInputDetail?.companyCategoryId}
                key={leftEyesNonGlassesInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, leftEyesNonGlassesInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <span>Có kính:</span>
              </div>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Mắt phải"
                name={rightEyesGlassesInputDetail?.companyCategoryId}
                key={rightEyesGlassesInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, rightEyesGlassesInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Mắt trái"
                name={leftEyesGlassesInputDetail?.companyCategoryId}
                key={leftEyesGlassesInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, leftEyesGlassesInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      );
    } else if (
      category.categoryName &&
      category.categoryName
        .toLowerCase()
        .indexOf(CATEGORY_CONSTANTS.THINH_LUC) > -1
    ) {
      const leftEarTalkingInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.TAI_TRAI_NOI_THUONG,
      );
      const leftEarWhisperingInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.TAI_TRAI_NOI_THAM,
      );
      const rightEarTalkingInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.TAI_PHAI_NOI_THUONG,
      );
      const rightEarWhisperingInputDetail = categories.find(
        (item: any) =>
          item.categoryName.toLowerCase() ===
          CATEGORY_CONSTANTS.TAI_PHAI_NOI_THAM,
      );
      return (
        <div>
          <Row>
            <Col span={4}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <span>Tai trái:</span>
              </div>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Nói thường"
                name={leftEarTalkingInputDetail?.companyCategoryId}
                key={leftEarTalkingInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, leftEarTalkingInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Nói thầm"
                name={leftEarWhisperingInputDetail?.companyCategoryId}
                key={leftEarWhisperingInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, leftEarWhisperingInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <span>Tai phải:</span>
              </div>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Nói thường"
                name={rightEarTalkingInputDetail?.companyCategoryId}
                key={rightEarTalkingInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, rightEarTalkingInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Nói thầm"
                name={rightEarWhisperingInputDetail?.companyCategoryId}
                key={rightEarWhisperingInputDetail?.companyCategoryId}
                style={{ margin: '10px 10px' }}
              >
                <Input
                  disabled={isDisabledPanel}
                  onChange={(e: any) => this.handleTextBoxOnChange(e, rightEarWhisperingInputDetail?.companyCategoryId)}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      );
    }
    return true;
  };

  renderMedicalExamOption = (
    type: string,
    details: any[],
    key: string,
    categoryName: string,
    parentCategoryId: string,
    isDisabledPanel: boolean,
  ) => {
    const options: any[] = [];
    switch (type) {
      case 'checkbox':
        const checkboxOptions: any[] = [];
        const { fields } = this.state;
        const field = fields.find((s: FieldData) => s.name === key);
        details.map((detail: any, index: number) =>
          checkboxOptions.push(
            detail.isAddNew ? (
              <Input
                value={detail.detailDescription}
                onChange={(e: any) =>
                  this.handleChangeNewDetailDescription(
                    e,
                    detail.companyCategoryDetailsId,
                    detail.companyCategoryID,
                  )
                }
                disabled={isDisabledPanel}
                size="middle"
                className="input-new-detail-category"
              />
            ) : (
              <>
                <Checkbox
                  value={detail.companyCategoryDetailsId}
                  onChange={(e: any) => this.handleCheckboxOnChange(e, key)}
                  disabled={isDisabledPanel}
                  defaultChecked={
                    (field &&
                      field.value &&
                      Array.isArray(field.value) &&
                      field.value.findIndex(
                        (i: string) => i === detail.companyCategoryDetailsId,
                      ) > -1) ||
                    false
                  }
                >
                  {detail.detailDescription}
                </Checkbox>
                <br />
              </>
            ),
          ),
        );

        options.push(
          <Form.Item className="checkbox-item" name={key} key={key}>
            {checkboxOptions}
          </Form.Item>,
        );

        options.push(
          <Button
            key="button"
            onClick={() =>
              this.handleAddNewCategoryDetail(key, details.length + 1)
            }
            className="btn-add-detail-category background-purple"
            shape="round"
            disabled={isDisabledPanel}
            icon={<PlusCircleOutlined />}
          >
            Thêm
          </Button>,
        );

        break;

      case 'radio':
        const radioOptions: any[] = [];
        details.map((detail: any, index: number) =>
          radioOptions.push(
            <Radio
              value={detail.companyCategoryDetailsId}
              disabled={isDisabledPanel}
            >
              {detail.detailDescription}
            </Radio>,
          ),
        );
        options.push(
          <Form.Item name={key} key={key}>
            <Radio.Group
              onChange={(e: any) =>
                this.handleRadioOnChange(e, key, parentCategoryId)
              }
              disabled={isDisabledPanel}
            >
              {radioOptions}
            </Radio.Group>
          </Form.Item>,
        );
        break;

      case 'datepicker':
        options.push(
          <Form.Item name={key} key={key}>
            <DatePicker
              disabled={isDisabledPanel}
              onChange={(e: any) => this.handleTextBoxOnChange(e, key)}
            />
          </Form.Item>,
        );
        break;

      case 'textarea':
        options.push(
          <Form.Item name={key} key={key}>
            <Input.TextArea
              rows={4}
              disabled={isDisabledPanel}
              onChange={(e: any) => this.handleTextBoxOnChange(e, key)}
            />
          </Form.Item>,
        );
        break;

      case 'textbox':
        options.push(
          <Form.Item name={key} key={key}>
            <Input
              disabled={isDisabledPanel}
              onChange={(e: any) => this.handleTextBoxOnChange(e, key)}
            />
          </Form.Item>,
        );
        break;

      case 'textboxnumber':
        //Custom cho tab Thể lực. Tính BMI từ cân nặng và chiều cao
        if (
          categoryName &&
          categoryName.toLowerCase().indexOf(CATEGORY_CONSTANTS.CHIEU_CAO) > -1
        ) {
          options.push(
            <Form.Item name={key} key={key}>
              <InputNumber
                className="physical-category"
                min={130}
                max={230}
                formatter={(value) => `${value}`.replace(/\./g, ',')}
                // parser={value => `${value}`.replace(/,/g, ".")}
                decimalSeparator=","
                onChange={(e: any) => this.setChieuCao(e, key)}
                disabled={isDisabledPanel}
              />
            </Form.Item>,
          );
        } else if (
          categoryName &&
          categoryName.toLowerCase().indexOf(CATEGORY_CONSTANTS.CAN_NANG) > -1
        ) {
          options.push(
            <Form.Item name={key} key={key}>
              <InputNumber
                className="physical-category"
                min={30}
                max={120}
                formatter={(value) => `${value}`.replace(/\./g, ',')}
                // parser={value => `${value}`.replace(/,/g, ".")}
                decimalSeparator=","
                onChange={(e: any) => this.setCanNang(e, key)}
                disabled={isDisabledPanel}
              />
            </Form.Item>,
          );
        } else {
          options.push(
            <Form.Item name={key} key={key}>
              <InputNumber
                className="physical-category"
                min={1}
                max={500}
                disabled={isDisabledPanel}
                onChange={(e: any) => this.handleNumberboxOnChange(e, key)}
              />
            </Form.Item>,
          );
        }
        break;

      case 'bmi': //custom type for BMI
        options.push(
          <Form.Item name={categoryName} key={key}>
            {/* <Paragraph copyable>{this.state.bmi}</Paragraph> */}
            <Input className="bmi-input" disabled></Input>
          </Form.Item>,
        );
        break;
    }

    return options;
  };

  setChieuCao = (e: any, name: string) => {
    this.handleNumberboxOnChange(e, name);
    this.setState({ chieuCao: e }, () => {
      this.caculateBMI();
    });
  };

  setCanNang = (e: any, name: string) => {
    this.handleNumberboxOnChange(e, name);
    this.setState({ canNang: e }, () => {
      this.caculateBMI();
    });
  };

  caculateBMI = () => {
    let { canNang, chieuCao } = this.state;
    if (chieuCao) chieuCao = chieuCao * 0.01;
    let bmiObject = calcBmi(canNang, chieuCao, false);

    let bmiName =
      bmiObject && bmiObject.name ? convertBmiValue(bmiObject.name) : '';

    let bmiValue =
      bmiObject && bmiObject.value ? bmiObject.value.toFixed(1) : 0;
    let bmiResult = bmiName
      ? bmiValue + ' (' + bmiName + ')'
      : bmiValue + ' (' + 'Sai định dạng chiều cao và cân nặng' + ')';

    this.state.formRef.current.setFieldsValue({
      BMI: bmiResult,
    });
  };

  renderMedicalCategoryClassification = (
    id: string,
    isDisabledPanel: boolean,
  ) => {
    const { fields } = this.state;
    const name = `${this.medicalCategoryClassification}${id}`;
    const currentValue = this.state.formRef.current.getFieldsValue()[name];
    return currentValue !== '0' ? (
      <Form.Item className="health-classification-ddl" name={name} key={id}>
        <Select
          className={`${this.medicalCategoryClassification}${id}`}
          placeholder="Phân loại sức khỏe"
          value={fields.find((s: any) => s.name === name)?.value}
          onChange={(e: any) => {
            this.handleClassificationChange(e, name);
          }}
          disabled={isDisabledPanel}
        >
          <Option value="1">Loại 1</Option>
          <Option value="2">Loại 2</Option>
          <Option value="3">Loại 3</Option>
          <Option value="4">Loại 4</Option>
          <Option value="5">Loại 5</Option>
        </Select>
      </Form.Item>
    ) : (
      <></>
    );
  };

  isCustomMedicalExamOptionRender = (categoryName: string) => {
    if (
      categoryName &&
      (categoryName.toLowerCase().indexOf(CATEGORY_CONSTANTS.THI_LUC) > -1 ||
        categoryName.toLowerCase().indexOf(CATEGORY_CONSTANTS.THINH_LUC) > -1)
    ) {
      return true;
    }
    return false;
  };

  renderMultiSubCategories = (categories: any[], parentCategoryId: string) => {
    const view: any[] = [];
    const activePanels: any[] = [];

    const { disabledTabs, fields, isShowModalEditEmployeeProfile } = this.state;

    const categoriesId: string[] = categories.map(
      (category: any) => category.companyCategoryId,
    );
    const disabledTab = (disabledTabs && disabledTabs[parentCategoryId]) || [];
    categories.map((category: any, index: number) => {
      const field = fields.find(
        (s: any) => s.name === category.companyCategoryId,
      );

      const isDisabledPanel: boolean =
        disabledTab.findIndex((s: any) => s === category.companyCategoryId) >
        -1;
      view.push(
        <Panel
          header={category.categoryName}
          key={category.companyCategoryId}
          disabled={isDisabledPanel}
          extra={
            category.hasClassification ? (
              this.renderMedicalCategoryClassification(
                category.companyCategoryId,
                isDisabledPanel,
              )
            ) : (
              <></>
            )
          }
          className={
            category.isHide
              ? 'hidden-row'
              : category.categoryType === 'checkbox' &&
                field &&
                field.value &&
                Array.isArray(field.value) &&
                field.value.length > 0
                ? 'background-yellow'
                : ''
          }
        >
          {this.isCustomMedicalExamOptionRender(category.categoryName)
            ? this.renderCustomMedicalExaminationOption(
              category,
              categories,
              isDisabledPanel,
            )
            : this.renderMedicalExamOption(
              category.categoryType,
              category.companyCategoryDetails,
              category.companyCategoryId,
              category.categoryName,
              parentCategoryId,
              isDisabledPanel,
            )}
        </Panel>,
      );

      //Push items to auto collapsed
      if (category.isCollapsed) {
        activePanels.push(category.companyCategoryId);
      }
    });

    return (
      <Collapse
        defaultActiveKey={activePanels}
      //defaultActiveKey={categories[0].companyCategoryId}
      >
        {view}
      </Collapse>
    );
  };

  intialInputForm = () => {
    const fields: FieldData[] = [];
    let { disabledTabs, linkedTabs, employeeInfo } = this.state;
    if (employeeInfo) {
      fields.push({
        name: 'BMI',
        value: employeeInfo.bmi,
      });
    }
    if (this.props.categories) {
      this.props.categories.forEach((category: any) => {
        const tempResponse = this.convertCategoryToFormValue(
          category,
          category,
          disabledTabs,
          linkedTabs
        );
        tempResponse.fields.map((field: FieldData) => fields.push(field));
        linkedTabs = tempResponse.linkedTabs;
        disabledTabs = tempResponse.disabledTabs;
      });
    }

    this.setState({ fields, disabledTabs });
  };

  convertCategoryToFormValue = (
    category: any,
    parentCategory: any,
    disabledTabs: any,
    linkedTabs: any
  ): any => {
    const fields: FieldData[] = [];
    const { employeeInfo } = this.state;
    if (category.subCategories && category.subCategories.length > 0) {
      category.subCategories.forEach((subCategory: any) => {
        const tempResponse = this.convertCategoryToFormValue(
          subCategory,
          parentCategory,
          disabledTabs,
          linkedTabs
        );
        tempResponse.fields.map((field: FieldData) => fields.push(field));
        disabledTabs = tempResponse.disabledTabs;
        linkedTabs = tempResponse.linkedTabs;
      });
    } else {
      if (
        employeeInfo.medicalExaminationResults &&
        employeeInfo.medicalExaminationResults.find(
          (s: any) => s.companyCategoryId === category.companyCategoryId,
        )?.medicalExaminationResultDetails?.length > 0
      ) {
        const details = employeeInfo.medicalExaminationResults.find(
          (s: any) => s.companyCategoryId === category.companyCategoryId,
        ).medicalExaminationResultDetails;

        if (category.categoryType === 'checkbox') {
          const field: FieldData = {
            name: category.companyCategoryId,
            value: [],
          };
          details.map((detail: any) =>
            field.value.push(detail.detailDescription),
          );
          fields.push(field);
        } else {
          fields.push({
            name: category.companyCategoryId,
            value: details[0].detailDescription,
          });
        }

        if (!disabledTabs[parentCategory.companyCategoryId]) {
          disabledTabs[parentCategory.companyCategoryId] = [];
        }
        
        const detail = category.companyCategoryDetails.find(
          (s: any) =>
            s.companyCategoryDetailsId === details[0].detailDescription,
        );

        if (detail && !linkedTabs[detail.companyCategoryId]) {
          linkedTabs[detail.companyCategoryId] = [];
        }

        if (detail && category.categoryType === 'radio') {
          [disabledTabs, linkedTabs] = this.processMakeTabDisable(
            detail,
            disabledTabs,
            linkedTabs,
            category.companyCategoryId,
            parentCategory.companyCategoryId,
          );
        }
      } else if (
        category.companyCategoryDetails &&
        category.companyCategoryDetails.find((s: any) => s.isDefault) &&
        category.categoryType === 'radio'
      ) {
        const detail = category.companyCategoryDetails.find(
          (s: any) => s.isDefault,
        );
        fields.push({
          name: category.companyCategoryId,
          value: detail?.companyCategoryDetailsId,
        });

        if (!disabledTabs[parentCategory.companyCategoryId]) {
          disabledTabs[parentCategory.companyCategoryId] = [];
        }

        if (!linkedTabs[detail.companyCategoryId]) {
          linkedTabs[detail.companyCategoryId] = [];
        }

        if (detail) {
          [disabledTabs, linkedTabs] = this.processMakeTabDisable(
            detail,
            disabledTabs,
            linkedTabs,
            category.companyCategoryId,
            parentCategory.companyCategoryId,
          );
        }
      } else {
        fields.push({
          name: category.companyCategoryId,
          value: category.categoryType === 'checkbox' ? [] : '',
        });
      }

      if (category.hasClassification) {
        if (
          employeeInfo.medicalExaminationResults &&
          employeeInfo.medicalExaminationResults.find(
            (s: any) => s.companyCategoryId === category.companyCategoryId,
          )
        ) {
          fields.push({
            name: `${this.medicalCategoryClassification}${category.companyCategoryId}`,
            value: `${employeeInfo.medicalExaminationResults.find(
              (s: any) => s.companyCategoryId === category.companyCategoryId,
            ).categoryHealthClass || 1
              }`,
          });
        } else {
          fields.push({
            name: `${this.medicalCategoryClassification}${category.companyCategoryId}`,
            value: '1',
          });
        }
      }
    }

    return { fields, disabledTabs, linkedTabs };
  };
  processMakeTabDisable = (
    detail: any,
    disabledTabs: any,
    linkedTabs: any,
    name: string,
    parentCategoryId: string,
  ): any => {
    const disabledTab = disabledTabs[parentCategoryId];
    const linkedTab = linkedTabs[detail.companyCategoryId]
    if (
      detail.makeDisable &&
      ['none', 'normal'].findIndex((s: any) => s === detail.makeDisable) > -1 &&
      detail.disableTabs
    ) {
      const relativeTabs = JSON.parse(detail.disableTabs);

      relativeTabs
        .filter(
          (s: string) => disabledTab.findIndex((d: string) => d === s) === -1,
        )
        .forEach((tabId: string) => {
          disabledTab.push(tabId);
        });
    }

    if (
      detail.makeDisable &&
      detail.makeDisable === 'abnormal' &&
      detail.disableTabs
    ) {
      const relativeTabs = JSON.parse(detail.disableTabs);

      relativeTabs
        .filter(
          (s: string) => linkedTab.findIndex((d: string) => d === s) === -1,
        )
        .forEach((tabId: string) => {
          linkedTab.push(tabId);
        });
    }

    return [disabledTabs, linkedTabs];
  };

  redirectToEmployeePage = () => {
    let employeeSearchingUrl =
      '/medical-examination-employee/' +
      this.state.employeeInfo.companyMedicalExaminationId;

    window.open(employeeSearchingUrl, '_blank');
  };

  redirectToCompanyProfilePage = () => {
    let companyProfileUrl =
      '/company/detail/' + this.state.examminationInfo.companyProfileId;

    window.open(companyProfileUrl, '_blank');
  };

  downloadEmployeeMedicalResult = async () => {
    const requestBody = {
      companyMedicalExaminationId: this.state.employeeInfo
        .companyMedicalExaminationId,
      employeeProfileId: this.state.employeeInfo.employeeProfileId,
    };
    try {
      this.setState({ loadingDownloadResult: true });
      const response = await downloadEmployeeMedicalResult(requestBody); 
      let fileName = this.state.employeeInfo.employeeNo 
                    ? `report-${this.state.employeeInfo.employeeName}-${this.state.employeeInfo.employeeNo}.xlsx`
                    : `report-${this.state.employeeInfo.employeeName}.xlsx`;

      FileDownload(
        response.data, fileName
      );
    } catch (e) {
      console.error(e);
      notification.error({ message: 'Có lỗi xảy ra khi tải file kết quả.' });
    } finally {
      this.setState({ loadingDownloadResult: false });
    }

    this.handleTabNavigation((this.props.categories.length - 1).toString());
  };

  handleCloseModal = () => {
    this.setState({ isShowModalEditEmployeeProfile: false });
  };
  handleUpdateEmployee = () => {
    const employeeId = this.props.match.params.employeeId;
    getEmployeeByEmployeeIdService(employeeId).then((response) => {
      if (response.data) {
        this.setState({ employeeInfo: response.data });
      }
    });
  };

  render() {
    const { categories } = this.props;
    const {
      fields,
      disabledTabs,
      employeeInfo,
      examminationInfo,
      isShowModalEditEmployeeProfile,
    } = this.state;
    const arrayHeaderButtons = [];

    arrayHeaderButtons.push(<Button
      onClick={this.redirectToCompanyProfilePage}
      shape="round"
      style={{ marginLeft: 20 }}
      type="primary"
      className="background-purple"
      icon={<ForwardOutlined />}
    >
      Tới trang thông tin
    </Button>);

    arrayHeaderButtons.push(<Button
      onClick={this.redirectToEmployeePage}
      shape="round"
      style={{ marginLeft: 20 }}
      type="primary"
      className="background-purple"
      icon={<ForwardOutlined />}
    >
      Tới danh sách NLĐ
    </Button>);

    return (
      <div className="App">
        <Descriptions
          className="employee-info-container  background-purple-light"
          title={this.state.companyName}
          extra={
            arrayHeaderButtons

          }
        >
          <Descriptions.Item label="Kỳ khám">
            <b>{examminationInfo.medicalExaminationName}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Ngày bắt đầu">
            <b>{moment(examminationInfo.startDate).format('DD/MM/yyyy')}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Ngày kết thúc">
            <b>{moment(examminationInfo.endDate).format('DD/MM/yyyy')}</b>
          </Descriptions.Item>
          <Descriptions.Item
            className="current-input-quantity"
            label="Số hiện tại"
          >
            <p className="current-employee-input-quantity">
              {examminationInfo.currentInputEmployeeQuantity}
            </p>
            <p className="current-employee-quantity">
              &nbsp;/&nbsp; {examminationInfo.employeeQuantity}
            </p>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          className="employee-info-container background-purple-light"
          title="Thông tin cá nhân"
          extra={
            <>
              <Button
                onClick={() =>
                  this.setState({ isShowModalEditEmployeeProfile: true })
                }
                type="primary"
                shape="round"
                className="background-purple"
                icon={<EditOutlined />}
              >
                Chỉnh sửa
              </Button>

              <Button
                type="primary"
                className="btn-input background-purple"
                shape="round"
                loading={this.state.loadingDownloadResult}
                onClick={() => this.downloadEmployeeMedicalResult()}
                icon={<ArrowDownOutlined />}
              >
                Kết quả
              </Button>
            </>
          }
        >
          <Descriptions.Item label="Họ và Tên">
            <b>{employeeInfo?.employeeName}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Giới tính">
            <b>{employeeInfo?.gender == '0' ? 'Nam' : 'Nữ'}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Ngày sinh">
            {' '}
            <b> {employeeInfo?.dateOfBirth}</b>
          </Descriptions.Item>

          <Descriptions.Item label="Bộ phận làm việc">
            {' '}
            {employeeInfo?.employeePosition}
          </Descriptions.Item>
          <Descriptions.Item label="Cơ quan">
            {employeeInfo?.employeeUnit}
          </Descriptions.Item>
          <Descriptions.Item label="Nhóm">
            {employeeInfo?.employeeBusinessUnit}
          </Descriptions.Item>
          <Descriptions.Item label="Mã nhân viên">
            {employeeInfo?.employeeNo}
          </Descriptions.Item>
          <Descriptions.Item label="Số điện thoại">
            {employeeInfo?.employeePhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {' '}
            {employeeInfo?.employeeEmail}
          </Descriptions.Item>

          <Descriptions.Item label="Ghi chú">
            {employeeInfo?.note}
          </Descriptions.Item>
        </Descriptions>

        <Card
          className="input-card-container"
          title={
            <p className="company-profile--card__header">
              Kết quả Khám sức khỏe
            </p>
          }
        >
          <Form
            name="input-medical-form"
            onFinish={this.onSaveMedicalInput}
            fields={fields}
            ref={this.state.formRef}
          >
            {this.state.formRef.current && (
              <Row>
                <Col span={20}>
                  <Tabs
                    type="card"
                    activeKey={this.state.step}
                    onTabClick={(key: string) => this.handleTabNavigation(key)}
                  >
                    {categories.map((category: any, index: number) => (
                      <TabPane
                        tab={category.categoryName}
                        key={index}
                      //style={{ maxHeight: 550, overflow: 'auto' }}
                      >
                        {category.subCategories.length > 0 ? (
                          this.renderMultiSubCategories(
                            category.subCategories,
                            category.companyCategoryId,
                          )
                        ) : (
                          <Input.TextArea
                            placeholder="Controlled autosize"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        )}
                      </TabPane>
                    ))}
                  </Tabs>
                </Col>
                <Col className="btn-col" span={4}>
                  <Affix offsetTop={320} onChange={(affixed) => { }}>
                    <Button
                      type="primary"
                      className="btn-input background-purple"
                      shape="round"
                      htmlType="submit"
                    >
                      Cập nhật
                    </Button>
                    <br></br>
                    <Button
                      type="default"
                      shape="round"
                      className="btn-input"
                      disabled={this.state.step === '0'}
                      onClick={() => {
                        this.handleTabNavigation(
                          (this.state.step - 1).toString(),
                        );
                      }}
                    >
                      Quay lại
                    </Button>
                    <br></br>
                    <Button
                      type="default"
                      shape="round"
                      className="btn-input"
                      disabled={
                        this.state.step === this.props.categories.length
                      }
                      onClick={() => {
                        this.handleTabNavigation(
                          (this.state.step - 0 + 1).toString(),
                        );
                      }}
                    >
                      Tiếp theo
                    </Button>
                  </Affix>
                </Col>
              </Row>
            )}

            <br />
            <br />
          </Form>
        </Card>
        <Modal
          title="Thông tin cá nhân"
          closable
          onCancel={this.handleCloseModal}
          visible={isShowModalEditEmployeeProfile}
          footer={null}
        >
          <EmployeeProfile
            closeModel={this.handleCloseModal}
            employeeInfo={employeeInfo}
            finishedUpdateEmployee={this.handleUpdateEmployee}
            medicalExaminationId={employeeInfo.companyMedicalExaminationId}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    categories: (state.app && state.app.companyCategoriesList) || [],
    companyCategories: (state.app && state.app.companyCategories) || [],
  };
};

export default connect(mapStateToProps)(MedicalExamInput);
