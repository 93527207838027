export const ACCOUNT_CONSTANTS = {
  GET_ALL_ACCOUNTS: 'GET_ALL_ACCOUNTS',
  GET_ALL_ACCOUNTS_SUCCESS: 'GET_ALL_ACCOUNTS_SUCCESS',
  GET_CURRENT_ACCOUNT: 'GET_CURRENT_ACCOUNT',
  GET_CURRENT_ACCOUNT_SUCCESS: 'GET_CURRENT_ACCOUNT_SUCCESS',
  CREATE_NEW_ACCOUNT: 'CREATE_NEW_ACCOUNT',
  CREATE_NEW_ACCOUNT_SUCCESS: 'CREATE_NEW_ACCOUNT_SUCCESS',
  CREATE_NEW_ACCOUNT_FAILED: 'CREATE_NEW_ACCOUNT_FAILED',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILED: 'DELETE_ACCOUNT_FAILED',
};
