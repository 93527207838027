import { AxiosResponse } from 'axios';
import { api } from '../utils/apiServices';

export const getEmployeeListByMedicalExaminationId = (
  params: any,
): Promise<AxiosResponse<any>> => {
  return api.get(`/EmployeeProfiles`, { params });
};

export const createEmployeeService = (
  data: any,
): Promise<AxiosResponse<any>> => {
  return api.post('/EmployeeProfiles', data);
};

export const updateEmployeeService = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  return api.put(`EmployeeProfiles/${id}`, data);
};

export const deleteEmployeeService = (
  id: string,
): Promise<AxiosResponse<any>> => {
  return api.delete(`EmployeeProfiles/${id}`);
};

export const getEmployeeByEmployeeIdService = (
  employeeId: string,
): Promise<AxiosResponse<any>> => {
  return api.get(`EmployeeProfiles/${employeeId}`);
};
