import React, { useEffect } from 'react';

import EmployeeTable from 'components/Employee/EmployeeTable';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers/';

const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Danh sách người lao động';
  });
  const { currentUser } = useSelector((state: RootState) => state.accounts);

  return (
    <div className="App">
      <EmployeeTable currentUser={currentUser} />
    </div>
  );
};

export default Home;
