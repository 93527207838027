import React, { ReactElement } from 'react';
import { Col, Layout, Row } from 'antd';
import logo from '../resources/img/logo.jpg';
import { BrowserView, MobileView } from 'react-device-detect';

const { Footer, Content } = Layout;
export interface LoginLayoutProps {
  children: ReactElement;
  authorized: boolean;
}

const LoginLayout: React.FC<LoginLayoutProps> = (props) => {
  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
      <Layout className="site-layout">
        {/* <Header
          className="site-layout-background"
          style={{ padding: 0}}
        >TDHDM</Header> */}
        <Content style={{ backgroundColor: '#fff' }}>
          <Row></Row>
          <Row>
            <Col span={4}></Col>
            <Col span={2}>
              <div className="login-logo-section">
                <img className="login-logo" src={logo} alt="Logo"></img>
              </div>
            </Col>

            <Col span={14}>
              <BrowserView>
                <div className="login-logo-section">Health</div>
                <div className="login-logo-section">&nbsp; Data</div>
                <div className="login-logo-section">&nbsp; Management</div>
                <div className="login-logo-section logo-last-item">
                  &nbsp; System
                </div>
              </BrowserView>

              <MobileView>
                <div className="login-logo-section-mobile"></div>
              </MobileView>
            </Col>
            {/* <Col span={10}>
              <div className="login-logo-section">Data Management System</div>
            </Col> */}
            {/* <Col span={5}>
              <div className="login-logo-section">Management</div>
            </Col>
            <Col span={4}>
              <div className="login-logo-section">System</div>
            </Col> */}
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={2}></Col>

            <Col span={8}>{props.children}</Col>
            {/* <Col span={2}></Col>
            <Col span={5}></Col>
            <Col span={4}></Col> */}
          </Row>
        </Content>
        <Footer style={{ backgroundColor: '#fff' }}>
          <Row gutter={32}>
            <Col style={{ textAlign: 'center' }} span={8}>
              Version 2.1
            </Col>
            
          </Row>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LoginLayout;
