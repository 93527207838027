import { checkExamminationResultService } from './../../services/companyServices';
import { put, call, all, fork, takeLatest } from 'redux-saga/effects';
import {
  getCompanyProfileByIdService,
  getCompanyCategoriesService,
} from 'services/companyServices';
import {
  getCompanyDetailByIdSuccessAction,
  getCompanyCategoriesSuccessAction,
  checkExamminationResultSuccessAction,
} from 'store/actions/company';
import { COMPANY_CONSTANTS } from 'store/constants';

function* getCompanyDetailByIdSaga({ payload }: any) {
  try {
    const response = yield call(getCompanyProfileByIdService, payload.id);
    yield put(getCompanyDetailByIdSuccessAction(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* getCompanyCategoriesSaga({ payload }: any) {
  try {
    const response = yield call(getCompanyCategoriesService, {
      companyMedicalExaminationId: payload.id,
      gender: payload.gender,
    });
    yield put(getCompanyCategoriesSuccessAction(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* checkExamminationResultSaga({ payload }: any) {
  try {
    const response = yield call(checkExamminationResultService, {
      companyMedicalExaminationId: payload.id,
    });

    yield put(checkExamminationResultSuccessAction(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* watchOnCompanies() {
  yield takeLatest(
    COMPANY_CONSTANTS.GET_DETAIL_BY_ID,
    getCompanyDetailByIdSaga,
  );

  yield takeLatest(
    COMPANY_CONSTANTS.GET_COMPANY_CATEGORIES,
    getCompanyCategoriesSaga,
  );

  yield takeLatest(
    COMPANY_CONSTANTS.CHECK_COMPANY_EXAMMINATION_RESULT,
    checkExamminationResultSaga,
  );
}

export default function* companiesSaga() {
  yield all([fork(watchOnCompanies)]);
}
