import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Text from 'antd/lib/typography/Text';

import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { changePasswordService } from './../../services/account.services';

const ChangePassword: React.FC<any> = (props) => {
  const [error, setError] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const history = useHistory();
  useEffect(() => {}, []);
  const handleSubmitForm = (e) => {
    setLoadingModal(true);
    changePasswordService(e)
      .then((response) => {
        setError('');
        notification.success({ message: 'Đổi mật khẩu thành công' });
        history.push('/');
      })
      .catch(({ response }) => {
        setError(response.data);
      })
      .finally(() => {
        setLoadingModal(false);
      });
  };
  return (
    <>
      <Card style={{ marginBottom: 16 }} title="Thay đổi mật khẩu">
        <Form name="change-password-form" onFinish={handleSubmitForm}>
          <Form.Item
            label="Mật khẩu hiện tại"
            required={true}
            key="currentPassword"
            name="currentPassword"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="Mật khẩu mới"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Bạn vui lòng nhập mật khẩu mới!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="newPasswordConfirm"
            label="Xác nhận mật khẩu mới"
            dependencies={['newPassword']}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bạn vui lòng xác nhận mật khẩu mới!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Mật khẩu không trùng khớp!'),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          {error && (
            <Form.Item style={{ marginBottom: 0 }}>
              <Text type="danger" className="center">
                {error}
              </Text>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              loading={loadingModal}
            >
              Cập nhật
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    currentUser: (state.accounts && state.accounts.currentUser) || null,
  };
};
export default connect(mapStateToProps)(withRouter(ChangePassword));
