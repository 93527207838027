export const COMPANY_CONSTANTS = {
  GET_DETAIL_BY_ID: 'GET_DETAIL_BY_ID',
  GET_DETAIL_BY_ID_SUCCESS: 'GET_DETAIL_BY_ID_SUCCESS',
  GET_COMPANY_CATEGORIES: 'GET_COMPANY_CATEGORIES',
  GET_COMPANY_CATEGORIES_SUCCESS: 'GET_COMPANY_CATEGORIES_SUCCESS',

  //Company exammination result
  CHECK_COMPANY_EXAMMINATION_RESULT: 'CHECK_COMPANY_EXAMMINATION_RESULT',
  CHECK_COMPANY_EXAMMINATION_RESULT_SUCCESS:
    'CHECK_COMPANY_EXAMMINATION_RESULT_SUCCESS',
};
